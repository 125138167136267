import { Box, Flex, Spinner, Text } from '@chakra-ui/react'
import type { IBallStoreModel, IFieldingPlacementStoreModel } from '@clsplus/cls-plus-data-models'
import { datadogRum } from '@datadog/browser-rum'
import { useLiveQuery } from 'dexie-react-hooks'
import { observer } from 'mobx-react-lite'
import { useEffect } from 'react'
import { Navigate, Route, Routes, useParams } from 'react-router-dom'
import useInterval from 'use-interval'

import { MatchStartingModal } from '../components/MatchStartingModal/MatchStartingModal'
import { SocketModal } from '../components/SocketModal/SocketModal'
import { GameStateProvider } from '../contexts/gameState.context'
import { db } from '../data/dexie/Database'
import { useMst } from '../data/stores/rootStore'
import Auth from '../helpers/auth'
import ballHelpers from '../helpers/ballHelpers'
import S3PHelpers from '../helpers/s3pHelpers'
import type { TimeMachineLocalStorage } from '../types'
import type {
  IDetailedMatchStore,
  ILocalMatchStore,
  ISettingsModel,
  ISocketStore,
  ITimelineStore,
} from '../types/models'
import type { ClspMode } from '../types/props'
import BattingSetup from './BattingSetup'
import BowlingSetup from './BowlingSetup'
import Metrics from './Metrics'
import { PostMatch } from './PostMatch'
import Scorebook from './Scorebook'
import { Scoring } from './Scoring'
import Settings from './Settings'
import Setup from './Setup'

const Game = observer(() => {
  const store = useMst()
  const {
    detailedMatches,
    balls,
    appSettings,
    timelineEvents,
    localMatches,
    socketStore,
    fieldingPlacements,
  }: {
    detailedMatches: IDetailedMatchStore
    balls: IBallStoreModel
    appSettings: ISettingsModel
    timelineEvents: ITimelineStore
    localMatches: ILocalMatchStore
    socketStore: ISocketStore
    fieldingPlacements: IFieldingPlacementStoreModel
  } = useMst()
  const { id, type } = useParams<{ id: string; type: ClspMode }>()
  const s3pDataCount = useLiveQuery(() => db.s3p.where({ matchId: id }).count())

  useInterval(() => {
    store.s3pSyncCheck(id)
  }, 300)

  useInterval(() => {
    // send ping every 1 min to ensure socket stays active and is not auto-disconnected
    if (id) store.sendPing(id, appSettings.appMode)
  }, 60000)

  useEffect(() => {
    // connect if we aren't already connected to this game
    if (id && ((socketStore.matchId && socketStore.matchId !== id) || !socketStore.matchId)) {
      const profile = Auth.getUserProfile()
      const tokens = Auth.getTokens()
      if (id && appSettings.appMode && profile?.email && tokens?.accessToken) {
        // eslint-disable-next-line max-len
        const queryString = `auth=${tokens.accessToken}&matchId=${id}&mode=${
          appSettings.manualScoring.active ? 'postMatch' : appSettings.appMode
        }&email=${profile.email}`
        store.connectSocket(queryString, id)
      }
    }
    return function cleanup() {
      store.closeSocket()
    }
  }, [appSettings.appMode, appSettings.manualScoring.active, id, store, socketStore.matchId])

  if (!id) return null
  if (type) {
    appSettings.setAppMode(type)
  }
  const appMode = appSettings.manualScoring.active ? 'postMatch' : appSettings.appMode
  detailedMatches.getMatch(id, appMode, socketStore.forceDataReloadMatch)
  timelineEvents.getEvents(id, appSettings.appMode, localMatches.getIsLocalMatch(id), socketStore.forceDataReloadEvents)

  const game = detailedMatches.results.get(id)?.match
  const gameTimelineEvents = timelineEvents.results.get(id)

  if (game) {
    ballHelpers.getBalls(
      balls,
      game,
      appSettings.appMode,
      localMatches.getIsLocalMatch(id),
      socketStore.forceDataReloadBalls
    )
  }

  useInterval(() => {
    if (game && type && type !== 'fielding' && import.meta.env.VITE_ENV !== 'DEV') {
      if (s3pDataCount && s3pDataCount > 0) {
        db.createS3PMessage(
          S3PHelpers.metadata(appSettings.manualScoring.active ? 'postMatch' : type, game),
          S3PHelpers.heartbeat()
        )
      }
    }
  }, 3000)

  useEffect(() => {
    // check if we need to ensure Time Machine is enabled
    const timeMachineString: string | null = window.localStorage.getItem('cls_plus_timeMachine')
    if (timeMachineString) {
      const timeMachineParsed: TimeMachineLocalStorage = JSON.parse(timeMachineString)
      if (timeMachineParsed.matchId === game?.id && timeMachineParsed.activated) {
        store.appSettings.timeMachine.setBaseline(timeMachineParsed.baseline)
        store.appSettings.timeMachine.setActivated(timeMachineParsed.activated, timeMachineParsed.matchId)
      }
    }
  }, [store.appSettings.timeMachine, game?.id])

  // TODO: Refactor this so it doesn't break resuming matches with existing balls
  // useEffect(() => {
  //   const addToLocalMatches = async () => {
  //     if (game && !localMatches.getIsLocalMatch(game.id)) {
  //       // add entry to localMatches
  //       const matchDb = await db.matches.get(game.id)
  //       if (matchDb) {
  //         const match = detailedMatches.createMatch(matchDb.matchSN)
  //         if (match) {
  //           localMatches.addLocalMatch(match)
  //         }
  //       }
  //     }
  //   }
  //   addToLocalMatches()
  // }, [game, detailedMatches, localMatches])

  if (
    detailedMatches.state === 'pending' ||
    timelineEvents.state === 'pending' ||
    (balls.activeRequests && balls.activeRequests.length > 0)
  ) {
    return (
      <Flex w="100%" h="calc(100vh - 42px)" alignItems="center" justifyContent="center" direction="column">
        <Spinner thickness="4px" speed="0.75s" emptyColor="cls.gray.200" color="cls.blue.400" size="xl" />
        <Box marginTop="14px">
          <Text fontWeight="bold" fontSize="2xl" textAlign="center">
            Loading Match
          </Text>
          <Text fontSize="lg" textAlign="center" marginTop="20px">
            Loading Match data
            <br />
            <br />
            Checking system for Ball Data
            <br />
            <br />
            Checking system for match Event data
            <br />
            <br />
            Setting up socket connection
          </Text>
        </Box>
      </Flex>
    )
  }

  if (game) {
    if (import.meta.env.VITE_ENV !== 'DEV') {
      const ddUser = datadogRum.getUser()

      const homeTeamName = game?.getHomeTeam()?.name
      const awayTeamName = game?.getAwayTeam?.name

      const authName = `${homeTeamName} vs ${awayTeamName} - ${game.id}`

      if (ddUser.id !== Auth.getUserProfile()?.['cognito:username'] || ddUser.name !== authName) {
        datadogRum.setUser({
          id: Auth.getUserProfile()?.['cognito:username'],
          name: authName,
        })
      }
    }

    return (
      <GameStateProvider game={game}>
        <Routes>
          <Route
            path="setup/*"
            element={
              <>
                <Setup mode={appSettings.appMode} timelineEvents={gameTimelineEvents} />
                {(appSettings.appMode === 'core' || appSettings.appMode === 'advanced') && (
                  <MatchStartingModal game={game} mode={appSettings.appMode} />
                )}
              </>
            }
          />
          <Route path="settings/*" element={<Settings mode={appSettings.appMode} />} />
          <Route path="inning-batting-setup" element={<BattingSetup mode={appSettings.appMode} />} />
          <Route path="inning-bowling-setup" element={<BowlingSetup mode={appSettings.appMode} />} />
          <Route
            path="scoring"
            element={
              <Scoring
                game={game}
                balls={balls}
                appSettings={appSettings}
                timelineEvents={gameTimelineEvents}
                fieldingPlacements={fieldingPlacements}
              />
            }
          />
          <Route path="scorebook/*" element={<Scorebook game={game} appSettings={appSettings} />} />
          <Route path="metrics" element={<Metrics game={game} />} />
          <Route path="post-match/*" element={<PostMatch mode={appSettings.appMode} />} />
          <Route path="*" element={<Navigate to="/" replace />} />
        </Routes>
        <SocketModal socketStore={socketStore} matchId={id} appMode={appSettings.appMode} />
      </GameStateProvider>
    )
  }
  return null
})

export default Game
