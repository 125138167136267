import { Box, Flex, Text } from '@chakra-ui/react'
import { indexOf } from 'lodash'
import { observer } from 'mobx-react-lite'

import { db } from '../../data/dexie/Database'
import { BoundaryOptions, OutfieldSpeedOptions } from '../../data/reference'
import { useMst } from '../../data/stores/rootStore'
import S3PHelpers from '../../helpers/s3pHelpers'
import type { VenueProps } from '../../types/props'
import { Button } from '../Buttons/Button'
import { SimpleStateButton } from '../Buttons/SimpleStateButton'
import SubHeading from '../Headings/SubHeading'
import type { TextFieldCallbackArgs } from '../TextField/TextField'
import TextField from '../TextField/TextField'

const Venue = observer(({ game, mode, currentBall, conditions }: VenueProps) => {
  const { balls } = useMst()
  const activeInning = game.getActiveInning()
  const firstBall = balls.getFirstBall(activeInning?.id || '')

  const changeVenueEnds = ({ id, value }: TextFieldCallbackArgs) => {
    if (!id) return
    game.updateVenueEndField(id, value)
  }

  const changeConditionsField = ({ value, target }: { value: string; target?: string | null }) => {
    if (!value || !target) return
    if (target === 'boundary') {
      game.setBoundary(value)
    } else if (target === 'outfield_speed') {
      game.setOutfieldSpeed(value)
    }

    if (mode !== 'fielding') {
      db.createS3PMessage(S3PHelpers.metadata(mode, game), S3PHelpers.pitchConditions(conditions, game), true)
    }
  }

  const handleSwapStartingEnd = () => {
    if (activeInning && game.venue?.venueEnds) {
      balls.swapEnds(activeInning?.id, game.venue?.venueEnds)
    }
  }

  return (
    <Box paddingTop="14px">
      <SubHeading text="Venue" secondary />
      <SubHeading text={game.venue?.fullName || 'Unknown Venue'} icon={['far', 'map-marker-alt']} tertiary />
      {game.venue?.venueEnds && (
        <Box paddingTop="21px">
          <SubHeading text="Ends" icon={['far', 'tag']} tertiary />
          <Flex
            direction="column"
            alignItems="center"
            padding={game.venue.venueEnds.length > 1 && currentBall ? '4px 0 7px' : '4px 0 14px'}
          >
            <Flex w="100%" direction="column" flex={3}>
              {game.venue.venueEnds.map((end, index) => (
                <Flex marginTop={index > 0 ? '4px' : '0px'} key={`venueEnd${end.id}`}>
                  <Flex flex={1}>
                    <TextField
                      key={`venueEnd${end.id}`}
                      id={end.id}
                      value={end.name}
                      target="name"
                      onChange={changeVenueEnds}
                      data-testid="changeVenueEndsTextField"
                    />
                  </Flex>
                  <Flex flex={1} alignItems="center">
                    {end.id === firstBall?.venueEnd?.id && (
                      <Text fontSize="14px" marginLeft="14px">
                        (Starting End)
                      </Text>
                    )}
                  </Flex>
                </Flex>
              ))}
            </Flex>
          </Flex>
          {game.venue.venueEnds.length > 1 && currentBall && (
            <Flex marginBottom="21px">
              <Button colorScheme="green" onClick={handleSwapStartingEnd} data-testid="swapStartingEndButton">
                Swap Starting End (This innings)
              </Button>
            </Flex>
          )}
        </Box>
      )}
      <SubHeading text="Boundary Set" icon={['far', 'expand-arrows-alt']} tertiary />
      <Flex direction="row" alignItems="center" padding="4px 0 14px">
        <Flex flex={3}>
          <SimpleStateButton
            onClick={changeConditionsField}
            value="IN"
            target="boundary"
            isActive={conditions?.boundaryTypeId === indexOf(BoundaryOptions, 'IN')}
            isFlex
            isMiddle
            data-testid="boundaryIn"
          >
            In
          </SimpleStateButton>
          <SimpleStateButton
            onClick={changeConditionsField}
            value="NORMAL"
            target="boundary"
            isActive={conditions?.boundaryTypeId === indexOf(BoundaryOptions, 'NORMAL')}
            isFlex
            isLast
            data-testid="boundaryNormal"
          >
            Normal
          </SimpleStateButton>
        </Flex>
      </Flex>
      <SubHeading text="Outfield Speed" icon={['far', 'tachometer-alt-fast']} tertiary />
      <Flex direction="row" alignItems="center" padding="4px 0 14px">
        <Flex flex={3}>
          <SimpleStateButton
            onClick={changeConditionsField}
            value="SLOW"
            target="outfield_speed"
            isActive={conditions?.outfieldSpeedTypeId === indexOf(OutfieldSpeedOptions, 'SLOW')}
            isFlex
            isFirst
            data-testid="outfieldSpeedSlow"
          >
            Slow
          </SimpleStateButton>
          <SimpleStateButton
            onClick={changeConditionsField}
            value="NORMAL"
            target="outfield_speed"
            isActive={conditions?.outfieldSpeedTypeId === indexOf(OutfieldSpeedOptions, 'NORMAL')}
            isFlex
            isMiddle
            data-testid="outfieldSpeedNormal"
          >
            Normal
          </SimpleStateButton>
          <SimpleStateButton
            onClick={changeConditionsField}
            value="FAST"
            target="outfield_speed"
            isActive={conditions?.outfieldSpeedTypeId === indexOf(OutfieldSpeedOptions, 'FAST')}
            isFlex
            isLast
            data-testid="outfieldSpeedFast"
          >
            Fast
          </SimpleStateButton>
        </Flex>
      </Flex>
    </Box>
  )
})

export default Venue
