import type { IMatchPlayerModel, IMatchTeamModel } from '@clsplus/cls-plus-data-models'
import { orderBy } from 'lodash'
import { v4 as uuid } from 'uuid'
import { stringify } from 'zipson'

import { RequestHandler } from '../data/api/RequestHandler'
import type { DBInningsBalls } from '../data/dexie/Database'
import Auth from '../helpers/auth'
import type { SocketMessage } from '../types'

// This function removes all deep references, and replaces them
// with just the ID - so this can work with MST and the backend properly
// Also now it will convert VenueEnds, Officials and TeamMember IDS to be match specific

// matchID_<object>ID
// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const ballDataCleaner = (ball: any, matchId: string) => {
  if (ball.venueEnd && ball.venueEnd.search('_') === -1) {
    ball.venueEnd = `${matchId}_${ball.venueEnd}`
  }

  // if (ball.batter) {
  //   ball.batterMp = ball.batter
  // }
  return ball
}

// This function removes all deep references, and replaces them
// with just the ID - so this can work with MST and the backend properly
// Also now it will convert VenueEnds
// matchID_<object>ID
// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const matchDataCleaner = (match: any) => {
  // venueEnds
  if (match.venue && match.venue.venueEnds) {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    match.venue.venueEnds = match.venue.venueEnds.map((end: any) => ({ ...end, id: `${match.id}_${end.id}` }))
  }

  return match
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const idCleaner = (d: any, type: string) => {
  const data = JSON.parse(JSON.stringify(d))
  if (type === 'MATCH') {
    // venueEnds
    if (data.venue && data.venue.venueEnds) {
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      data.venue.venueEnds = data.venue.venueEnds.map((end: any) => {
        const splitID = end.id.split('_')
        if (splitID.length > 1) {
          return { ...end, id: splitID[1] }
        } else {
          return { ...end, id: splitID[0] }
        }
      })
    }
    if (data.matchNotes && typeof data.matchNotes !== 'string') {
      data.matchNotes = JSON.stringify(data.matchNotes)
    }
  }

  if (type === 'BALL') {
    let splitID
    if (data.venueEnd) {
      splitID = data.venueEnd.split('_')
      if (splitID.length > 1) {
        data.venueEnd = splitID[1]
      } else {
        data.venueEnd = splitID[0]
      }
    }
    return data
  }
  return data
}

export const formatSocketMessage = (
  action: string,
  matchId: string,
  mode: string,
  coverageLevelId: number | null,
  messageId: string,
  data: Record<string, unknown>,
  inningsId?: string,
  competitionId?: string,
  inningsMatchOrder?: number
) => {
  const message: SocketMessage = {
    action,
    matchId,
    scoringMode: mode,
    coverageLevelId,
    messageId,
    messageTimestamp: new Date().toISOString(),
    data: action === 'updateMatch' ? stringify(data) : JSON.stringify(data),
    inningsId: inningsId,
    competitionId: competitionId,
    inningsMatchOrder: inningsMatchOrder,
  }
  return JSON.stringify(message)
}

export const overIdPatcher = (innings: DBInningsBalls) => {
  // This function iterates over every ball in an innings to ensure that it contains an overId
  // A ball would likely only not have an overId, if it was created before we implemented overId
  // This ball could come from localDB or S3 cache...I think

  if (!innings.balls || !innings.balls.length) {
    return innings
  }

  const sortedBalls = orderBy(innings.balls, ['overNumber', 'ballNumber'])
  const patchedBalls = sortedBalls.map((ball, idx) => {
    if (ball.overId) return ball
    if (ball.ballNumber === 1) {
      // first ball of over, create new uuid
      ball.overId = uuid()
      return ball
    }
    // otherwise just get the overId from the ball before?
    if (sortedBalls[idx - 1]) {
      ball.overId = sortedBalls[idx - 1].overId
    }
    return ball
  })
  return { ...innings, balls: patchedBalls }
}

export const postPlayer = async (
  team: IMatchTeamModel,
  playerId: string,
  activeReasonId: number,
  setRecords: React.Dispatch<React.SetStateAction<IMatchPlayerModel[]>>,
  setAdding: React.Dispatch<React.SetStateAction<boolean>>
) => {
  // construct props
  const tokens = Auth.getTokens()
  const method = 'POST'
  const url = `${import.meta.env.VITE_API_URL}matchteamplayer`
  const params = {}
  const body = {
    playerId: playerId,
    matchTeamId: team.id,
    activeReasonId: activeReasonId,
  }

  // make request
  try {
    const response = await RequestHandler({
      method,
      url,
      params,
      body: JSON.stringify(body),
      headers: { Authorization: `Bearer ${tokens?.accessToken}` },
    })
    if (response instanceof Response && response.ok) {
      const data = await response.json()
      if (data && data.playerId === body.playerId) {
        team.addPlayer(data)
        setRecords(team.getPlayersInBattingOrder())
      }
    } else {
      // we got a response but it was not ok
      // for now just mark this request as done
      // because we don't have scope to handle this better
    }
    setAdding(false)
  } catch (error) {
    console.warn('Error posting MatchTeamPlayer', error) // eslint-disable-line no-console
    setAdding(false)
  }
}
