import { Flex, Text } from '@chakra-ui/react'

import type { EventItemProps } from '../../types/props'
import Undo from './Undo'

const EventItem = ({ event, mode, editBall, hasUndo, undoDisabled, triggerUndo }: EventItemProps) => (
  <Flex alignItems="center" justifyContent="center" userSelect="none" key={`${event.eventTime}`} w="100%" h="60px">
    <Flex
      flex={1}
      backgroundColor="#615f5f"
      borderWidth="1px"
      borderColor="gray.200"
      borderStyle="solid"
      borderRadius="4px"
      paddingX="7px"
      paddingY="4px"
      w={hasUndo && !undoDisabled ? '85%' : '100%'}
      h="52px"
    >
      <Flex flex={1}>
        <Flex flex={1} align="center" justifyContent="flex-start">
          <Text w="100%" color="cls.white.100" fontSize="12px" fontStyle="italic">
            {event.humanTime}
          </Text>
        </Flex>
        <Flex flex={2} align="center" justifyContent="flex-end">
          <Text
            w="100%"
            color="cls.white.100"
            fontSize="12px"
            fontWeight="bold"
            textAlign="right"
            textTransform="uppercase"
          >
            {event.textDescription}
          </Text>
        </Flex>
      </Flex>
    </Flex>
    {hasUndo && !undoDisabled && (
      <Flex h="100%" py="5px" justify="flex-end">
        <Undo
          mode={mode}
          editBall={editBall}
          onClick={() => {
            if (triggerUndo) triggerUndo(event)
          }}
        />
      </Flex>
    )}
  </Flex>
)

export default EventItem
