import type { IBallModel, ITimelineEventModel } from '@clsplus/cls-plus-data-models'
import type { Tracer } from '@opentelemetry/api'
import api from '@opentelemetry/api'
import { ZoneContextManager } from '@opentelemetry/context-zone'
import { ZipkinExporter } from '@opentelemetry/exporter-zipkin'
import { Resource } from '@opentelemetry/resources'
import { SimpleSpanProcessor } from '@opentelemetry/sdk-trace-base'
import { WebTracerProvider } from '@opentelemetry/sdk-trace-web'
import { SemanticResourceAttributes } from '@opentelemetry/semantic-conventions'
import { zonedTimeToUtc } from 'date-fns-tz'
import Dexie from 'dexie'
import { isNil } from 'lodash'
import type { SnapshotOrInstance } from 'mobx-state-tree'
import { v4 as uuidV4, v5 as uuidV5 } from 'uuid'

import Auth from '../../helpers/auth'
import type { IClspMatchModel } from '../../types/models'
import { initialAppContext } from '../stores/rootStore'

export const dataUUIDNamespace = '66c8caaf-4fd3-46fd-a9e2-75b747d1d75b'

export type S3PMetadata = {
  scoringMode: string
  coverageLevel: number | null
  matchId: string
  competitionId?: string
  inningsId?: string
  inningsMatchOrder?: number
  overNumber?: number
  ballNumber?: number
  relatedRecordId?: string
}

export type S3pPayload = {
  readonly type: string
  readonly sport_event_state?: any
  readonly [key: string]: unknown
}

type DBS3PMessage = S3PMetadata & {
  id?: string
  user?: string
  timestamp: string
  traceParent: string
  traceState: string | undefined
  messageId: string
  syncRequired: number
  data: string
}

export type DBMatch = {
  matchId: string
  syncRequired: number
  matchSN: SnapshotOrInstance<IClspMatchModel>
  message: string
}

export type DBInningsBalls = {
  inningsId: string
  matchId: string
  balls: SnapshotOrInstance<IBallModel>[]
}

export type DBEvent = {
  key: string
  events: SnapshotOrInstance<ITimelineEventModel>[]
}

export type DBTimelineMessage = {
  timestamp: string
  messageId: string
  matchId: string
  syncRequired: number
  message: string
  type: string
}

class Database extends Dexie {
  matches: Dexie.Table<DBMatch, string>
  balls: Dexie.Table<DBInningsBalls, string>
  events: Dexie.Table<DBEvent, string>
  timeline: Dexie.Table<DBTimelineMessage, number>
  s3p: Dexie.Table<DBS3PMessage, number>
  tracer: Tracer

  constructor() {
    super('CLSP_Database')

    this.version(3).stores({
      matches: 'matchId, syncRequired',
      balls: 'inningsId, matchId',
      events: 'key',
      timeline: '++id, matchId, messageId, timestamp, syncRequired, type, [matchId+syncRequired]',
      s3p: '++id, matchId, inningsId, inningsMatchOrder, competitionId, messageId, user, scoringMode, timestamp, syncRequired, [matchId+syncRequired], [messageId+syncRequired]', // eslint-disable-line max-len
    })

    this.matches = this.table('matches')
    this.balls = this.table('balls')
    this.events = this.table('events')
    this.timeline = this.table('timeline')
    this.s3p = this.table('s3p')

    // Configure Distributed Tracing Provider
    const provider = new WebTracerProvider({
      resource: new Resource({
        [SemanticResourceAttributes.SERVICE_NAME]: 'OpenTelemetry CLSPlus',
      }),
    })

    provider.addSpanProcessor(
      new SimpleSpanProcessor(
        new ZipkinExporter({
          url: import.meta.env.VITE_TRACING_HOST,
          headers: {
            'Api-Key': import.meta.env.VITE_TRACING_API_KEY,
            'Data-Format': 'zipkin',
            'Data-Format-Version': '2',
          },
        })
      )
    )

    provider.register({
      contextManager: new ZoneContextManager(),
    })

    this.tracer = api.trace.getTracer('CLSP')
  }

  createS3PMessage = async (metadata: S3PMetadata, payload?: S3pPayload, forceUUIDv5?: boolean) => {
    if (payload) {
      const timestamp = zonedTimeToUtc(new Date(), Intl.DateTimeFormat().resolvedOptions().timeZone).toISOString()

      // Create Distributed Tracing Span
      const span = this.tracer.startSpan(`${import.meta.env.VITE_SITE_NAME} -> LDC`)
      span.setAttribute('environment', import.meta.env.VITE_DATADOG_ENV)
      span.end()
      const context = span.spanContext()
      const traceParent = '00-' + context.traceId + '-' + context.spanId + '-0' + context.traceFlags
      const traceState = context.traceState?.serialize()

      let dataUUID = ''
      if (forceUUIDv5) {
        // used for match-specific messages that need a consistent ID across all modes
        let name = `${metadata.matchId}_${payload.type}`
        // Can optionally add a relatedRecordId to the metadata to force a consistent uuidv5 for specific events
        // (useful for regenerating the same event ID for an undo/delete message)
        if (metadata.relatedRecordId) {
          name += `_${metadata.relatedRecordId}`
        }
        dataUUID = uuidV5(name, dataUUIDNamespace)
      } else if (!isNil(payload.over_number) && !isNil(payload.ball_number)) {
        // used for ball-specific messages that need a consistent ID across all modes
        dataUUID = uuidV5(
          `${metadata.matchId}_${payload.innings_order ?? metadata.inningsMatchOrder}_${payload.over_number}_${
            payload.ball_number
          }_${payload.type}`,
          dataUUIDNamespace
        )
      } else if (payload.type === 'Event.Sport.Cricket.Dismissal') {
        // used for dismissals so each wicket in the same innings can be deleted using a consistent ID
        dataUUID = uuidV5(
          `${metadata.matchId}_${metadata.inningsMatchOrder}_${payload.fall_of_wicket_number}_${payload.batter_id}_${payload.dismissal_type}_${payload.type}`, // eslint-disable-line max-len
          dataUUIDNamespace
        )
      } else if (
        payload.type === 'Event.Sport.Cricket.Over' ||
        payload.type === 'State.Sport.Cricket.Statistics.SportEvent.Over'
      ) {
        // used for over related messages that need a consistent ID across all modes
        dataUUID = uuidV5(
          `${metadata.matchId}_${metadata.inningsMatchOrder}_${payload.over_number}_${payload.type}`, // eslint-disable-line max-len
          dataUUIDNamespace
        )
      } else if (payload.type.includes('State.Sport.Cricket.Statistics.SportEvent')) {
        // used for innings state messages that need a consistent ID across all modes
        dataUUID = uuidV5(
          `${metadata.matchId}_${metadata.inningsMatchOrder}_${payload.last_over_number}_${payload.last_ball_number}_${payload.type}`, // eslint-disable-line max-len
          dataUUIDNamespace
        )
      } else if (!isNil(payload.sport_event_state)) {
        // used for innings state messages that need a consistent ID across all modes
        dataUUID = uuidV5(
          `${metadata.matchId}_${metadata.inningsMatchOrder}_${payload.sport_event_state.current_over_number}_${payload.sport_event_state.current_ball_number}_${payload.type}`, // eslint-disable-line max-len
          dataUUIDNamespace
        )
      } else {
        // use a standard v4 UUID for all other messages
        dataUUID = uuidV4()
      }
      const messageId = uuidV4()
      await this.s3p.put({
        user: Auth.getUserProfile()?.email,
        scoringMode: metadata.scoringMode,
        coverageLevel: metadata.coverageLevel,
        messageId: messageId,
        matchId: metadata.matchId,
        inningsId: metadata.inningsId,
        inningsMatchOrder: metadata.inningsMatchOrder,
        competitionId: metadata.competitionId,
        timestamp: timestamp,
        traceParent: traceParent,
        traceState: traceState,
        syncRequired: 1,
        data: JSON.stringify({
          meta: {
            sport_event_id: metadata.matchId,
            coverage: {
              id: null, // This needs to be replaced in the backend when the push url is generated
              source_role: initialAppContext.store.appSettings.appMode,
            },
          },
          payload: {
            event_time: timestamp,
            id: dataUUID,
            ...payload,
          },
        }),
      })
    }
  }

  getTimelineMessageToSync(matchId: string) {
    return this.timeline.where('[matchId+syncRequired]').equals([matchId, 1]).sortBy('timestamp')
  }

  syncMessage(type: string, messageId: string, matchId: string | undefined) {
    if (type === 'updateBall' || type === 'deleteBall' || type === 'updateEvent') {
      this.timeline.where({ messageId: messageId }).modify(changes => (changes.syncRequired = 0))
    }
    if (matchId && type === 'updateMatch') {
      this.matches.update(matchId, { syncRequired: 0 })
    }
  }
}

const db = new Database()

export { db }
