import type {
  IBallModel,
  IBallStoreModel,
  IBattingPerformanceModel,
  IInningModel,
  IMatchConditionsModel,
  IMatchModel,
  IMatchOfficialModel,
  IMatchPlayerModel,
  IMatchStaffModel,
  IMatchTeamModel,
} from '@clsplus/cls-plus-data-models'
import {
  getBallsRemaining,
  getOversRemaining,
  getOversValue,
  getRunRate,
  getRunRateRequired,
} from '@clsplus/cricket-logic'
import { flatten, includes, isNil, round, sortBy } from 'lodash'
import { v5 as uuidV5 } from 'uuid'

import type { S3PMetadata, S3pPayload } from '../data/dexie/Database'
import { dataUUIDNamespace, db } from '../data/dexie/Database'
import type { BowlerTypeOptions, HandedTypeOptions, PlayerActiveReasonTypes } from '../data/reference'
import {
  BoundaryOptions,
  MatchStatus,
  MatchTeamRoleOptions,
  OfficialTypeOptions,
  OutfieldSpeedOptions,
  PartnershipStatusTypes,
  PitchGrassOptions,
  PitchMoistureOptions,
  PitchQualityOptions,
  ShotZoneOptions,
  WeatherAtmosOptions,
  WeatherRainOptions,
  WeatherTemperatureOptions,
  WeatherWindOptions,
  WicketKeeperPositionOptions,
} from '../data/reference'
import * as Reference from '../data/reference'
import type { IClspMatchModel } from '../types/models'
import type { ClspMode } from '../types/props'
import MatchHelpers from './matchHelpers'

// S3P-specific types
type CompetitorPlayer = {
  id: string
  name: string
  substitute: boolean
  active_status: boolean
  captain: boolean
  vice_captain: boolean
  wicket_keeper: boolean
  shirt_number?: number
  selection_number?: number
  active_reason?: typeof PlayerActiveReasonTypes[number]
  bowling_type?: typeof BowlerTypeOptions[number]
  bowling_hand?: typeof HandedTypeOptions[number]
  batting_hand?: typeof HandedTypeOptions[number]
  throwing_hand?: typeof HandedTypeOptions[number]
}

type CompetitorOfficial = {
  id: string
  name: string
  official_type?: string
}

// Common S3P object definitions
const commonCompetitor = (team: IMatchTeamModel) => {
  return {
    id: team.teamId,
    number: team.isHome ? 1 : 2,
  } as const
}
const commonLocationXY = (x: number, y: number) => {
  return {
    type: 'CARTESIAN_PERCENT',
    x,
    y,
  } as const
}
const commonLocationRadial = (angle: number, length: number) => {
  return {
    type: 'RADIAL_PERCENT',
    angle,
    length,
  } as const
}
const commonMatchState = (game: IMatchModel) => {
  const activeInning = game.getActiveInning(true)
  if (!activeInning) return

  // calculate current state (day, overs, balls)
  const currentDate = new Date()
  currentDate.setHours(currentDate.getHours() - 4) // -4 hours from current time
  const currentDay =
    game.matchDates.find(date => {
      return date.startDateTime && date.startDateTime > currentDate.toISOString()
    })?.matchDayNum ?? 1
  const currentOvers = commonCurrentOversBowled(
    activeInning.progressiveScores.oversBowled,
    game.matchConfigs.ballsPerOver
  )
  const currentOverNumber = Number(currentOvers[0])
  const currentBallNumber = currentOvers.length > 1 ? Number(currentOvers[currentOvers.length - 1]) : 0

  // calculate current status of the match
  const sportEventStatus =
    activeInning.inningsMatchOrder === 1 && currentOverNumber === 0 && currentBallNumber === 0
      ? 'ABOUT_TO_START'
      : !isNil(game.matchStatusId)
      ? commonMatchStatus(MatchStatus[game.matchStatusId], game.getActiveMatchBreak?.getMatchBreakType === 'STUMPS')
      : null

  return {
    current_innings_order: activeInning.inningsMatchOrder,
    current_day: currentDay,
    current_over_number: currentOverNumber,
    current_ball_number: currentBallNumber,
    scores: game.matchTeams.map(t => {
      return {
        competitor: commonCompetitor(t),
        innings: t.innings.map(i => {
          return {
            innings_order: i.inningsMatchOrder,
            runs: i.progressiveScores.runs,
            wickets: i.progressiveScores.wickets,
          }
        }),
      }
    }),
    ...(sportEventStatus && { sport_event_status: sportEventStatus }),
    ...(game.matchDls &&
      game.matchDls?.targetScore && {
        target_score: game.matchDls.targetScore,
        ...(game.matchConfigs.maxOvers && {
          overs_to_play: getOversRemaining({
            oversBowled: activeInning.progressiveScores.oversBowled || '0',
            oversPerInnings: game.matchConfigs.maxOvers.toString(),
            oversTarget: game.matchDls.targetOvers || undefined,
          }),
        }),
      }),
    ...(game.matchConfigs.maxOvers &&
      activeInning.inningsMatchOrder % 2 === 0 && {
        // add RRR for chasing team in limited overs match (normal innings AND super over)
        required_run_rate: getRunRateRequired({
          runsRequired: MatchHelpers.getRunsRequired(
            game.getBattingTeam(),
            game.getBowlingTeam(),
            activeInning,
            game.matchDls?.targetScore
          ),
          oversBowled: activeInning.progressiveScores.oversBowled || 0,
          oversPerInnings: MatchHelpers.getOversPerInnings(
            activeInning,
            game.matchConfigs.maxOvers || 0,
            game.matchDls?.targetOvers
          ),
          ballsPerOver: game.matchConfigs.ballsPerOver || 6,
        }),
      }),
  } as const
}
const commonMatchStatus = (status: string, isBreakStumps?: boolean) => {
  let statusFormatted: string = status
  switch (status) {
    case 'COMPLETED':
    case 'RESULT':
    case 'DRAWN':
    case 'TIED':
    case 'FORFEIT':
      statusFormatted = 'ENDED'
      break
    case 'IN_PROGRESS_PLAYING':
      statusFormatted = 'IN_PROGRESS'
      break
    case 'IN_PROGRESS_IN_BREAK':
      statusFormatted = isBreakStumps ? 'STUMPS' : 'IN_PROGRESS'
      break
  }
  return statusFormatted
}
const commonOfficialType = (type: string) => {
  let typeFormatted: string = type
  switch (type) {
    case 'THIRD_UMPIRE':
      typeFormatted = 'UMPIRE_THIRD'
      break
    case 'FOURTH_UMPIRE':
      typeFormatted = 'UMPIRE_FOURTH'
      break
    case 'HEAD_COACH':
      typeFormatted = 'COACH_HEAD'
      break
    case 'ASSISTANT_COACH':
      typeFormatted = 'COACH_ASSISTANT'
      break
    case 'TEAM_MANAGER':
      typeFormatted = 'MANAGER'
      break
  }
  return typeFormatted
}
const commonInningsFinishReason = (finishReason?: string) => {
  return finishReason ? (finishReason === 'DECLARED' || finishReason === 'FORFEIT' ? finishReason : 'FINISH') : null
}
const commonCurrentOversBowled = (oversBowled: string | null, ballsPerOver?: number | null) => {
  let currentOvers = oversBowled?.split('.') || ['0']
  if (currentOvers.length === 1 && currentOvers[0] !== '0') {
    // if overs value is a whole number, then we need to roll it back to get ...
    // ... over & ball display number values for the previous ball bowled in the innings
    currentOvers = getOversValue({
      currentValue: oversBowled || '0',
      undo: true,
      ballsPerOver: (ballsPerOver || 6) + 1,
    })
      .toString()
      .split('.')
  }
  return currentOvers
}
const commonBallMessageType = 'Event.Sport.Cricket.Ball'
const commonBowlingMessageType = 'Event.Sport.Cricket.Bowling'

const S3PHelpers = (function () {
  const metadata = (
    mode: ClspMode | 'postMatch',
    game: IMatchModel,
    innings?: IInningModel,
    ball?: IBallModel,
    relatedRecordId?: string
  ): S3PMetadata => {
    // compose metadata required by CLS+ to be in the POST body
    let inn = innings
    if (!inn) {
      inn = game.getActiveInning(true)
    }

    return {
      scoringMode: mode,
      coverageLevel: game.matchConfigs.coverageLevelId,
      matchId: game.id,
      competitionId: game.getCompetitionId || undefined,
      inningsId: inn?.id || undefined,
      inningsMatchOrder: inn?.inningsMatchOrder || undefined,
      overNumber: ball ? ball.overNumber : undefined,
      ballNumber: ball ? ball.ballNumber : undefined,
      relatedRecordId,
    } as const
  }

  const appeal = (game: IMatchModel, ball: IBallModel, innings?: IInningModel, appealType?: string) => {
    let inn = innings
    if (!inn) {
      inn = game.getActiveInning(true)
    }
    if (!inn) return

    return {
      type: 'Event.Sport.Cricket.Appeal',
      ...(appealType && { appeal_type: appealType === 'LBW' ? 'LEG_BEFORE_WICKET' : appealType }),
      innings_order: inn.inningsMatchOrder,
      over_number: ball.overNumber + 1,
      ball_number: ball.ballNumber,
      sport_event_state: commonMatchState(game),
      innings: innings,
    } as const
  }

  const ball = (status: string, game: IMatchModel, ball: IBallModel, innings?: IInningModel, deleted = false) => {
    let inn = innings
    if (!inn) {
      inn = game.getActiveInning(true)
    }
    if (!inn) return

    return {
      type: commonBallMessageType,
      status: status,
      ball_number: ball.ballNumber,
      over_number: ball.overNumber + 1,
      innings_order: inn.inningsMatchOrder,
      free_hit: ball.freeHit,
      ...(!isNil(ball.ballDisplayNumber) && { ball_display_number: ball.ballDisplayNumber }),
      ...(ball.batterMp && { striker_id: ball.batterMp.player.id }),
      ...(ball.batterNonStrikeMp && { nonstriker_id: ball.batterNonStrikeMp.player.id }),
      ...(ball.bowlerMp && { bowler_id: ball.bowlerMp.player.id }),
      ...(deleted && { deleted: true }),
      sport_event_state: commonMatchState(game),
    } as const
  }

  const batting = (
    mode: ClspMode,
    ball: IBallModel,
    game: IMatchModel,
    innings?: IInningModel,
    bowlerRunningIn?: boolean
  ) => {
    let inn = innings
    if (!inn) {
      inn = game.getActiveInning(true)
    }
    if (!inn) return

    return {
      type: 'Event.Sport.Cricket.Batting',
      batter_id: ball.batterMp?.player.id || undefined,
      innings_order: inn.inningsMatchOrder || undefined,
      over_number: ball.overNumber + 1,
      ball_number: ball.ballNumber,
      ...(inn.getBattingTeam && { batting_competitor: commonCompetitor(inn.getBattingTeam) }),
      ...(ball.battingAnalysis && {
        attacking: ball.battingAnalysis.shots.attacking,
        through_field: ball.battingAnalysis.shots.throughField,
        in_the_air: ball.battingAnalysis.shots.inTheAir,
        shot_type: bowlerRunningIn ? undefined : ball.battingAnalysis.shots.getShotType || undefined,
        shot_contact: bowlerRunningIn ? undefined : ball.battingAnalysis.shots.getShotContactType || undefined,
        ...(mode === 'advanced' && {
          arrival: ball.battingAnalysis.arrival
            ? commonLocationXY(ball.battingAnalysis.arrival.x, ball.battingAnalysis.arrival.y)
            : undefined,
          wagon_wheel:
            !isNil(ball.battingAnalysis.shots.angle) && !isNil(ball.battingAnalysis.shots.length)
              ? commonLocationRadial(ball.battingAnalysis.shots.angle, ball.battingAnalysis.shots.length)
              : undefined,
          wagon_wheel_zone: !isNil(ball.battingAnalysis.shots.shotZoneId)
            ? ShotZoneOptions[ball.battingAnalysis.shots.shotZoneId]
            : undefined,
          body_contact: ball.battingAnalysis.shots.getBodyContactType || undefined,
          footwork: ball.battingAnalysis.getFootworkType || undefined,
          shot_side: ball.battingAnalysis.shots.getShotSide || undefined,
        }),
      }),
      sport_event_state: commonMatchState(game),
    } as const
  }

  const bowlerChange = (newBowler: IMatchPlayerModel, prevBowler: IMatchPlayerModel, game: IMatchModel) => {
    return {
      type: 'Event.Sport.Cricket.BowlerChange',
      reason: 'OTHER',
      new_bowler_id: newBowler.player.id,
      previous_bowler_id: prevBowler.player.id,
      sport_event_state: commonMatchState(game),
    } as const
  }

  const bowling = (
    mode: ClspMode,
    ball: IBallModel,
    game: IMatchModel,
    innings?: IInningModel,
    delivered?: boolean
  ) => {
    let inn = innings
    if (!inn) {
      inn = game.getActiveInning(true)
    }
    if (!inn) return

    return {
      type: commonBowlingMessageType,
      striker_id: ball.batterMp?.player.id || undefined,
      over_number: ball.overNumber + 1,
      ball_number: ball.ballNumber,
      innings_order: inn?.inningsMatchOrder || undefined,
      status: ball.timestamps.firstInput || delivered ? 'DELIVERED' : 'RUNNING',
      ...(inn?.getBattingTeam && {
        batting_competitor: commonCompetitor(inn.getBattingTeam),
      }),
      ...(inn?.getBowlingTeam && {
        bowling_competitor: commonCompetitor(inn.getBowlingTeam),
      }),
      ...(ball.bowlerMp && {
        bowler_id: ball.bowlerMp.player.id,
        hand: ball.bowlerMp.getBowlingHanded || undefined,
        bowler_type: ball.bowlerMp.getBowlingType,
      }),
      ...(ball.bowlingAnalysis && {
        approach: ball.bowlingAnalysis.getBowlerApproachType,
        ...(mode === 'advanced' && {
          ball_type: ball.bowlingAnalysis.getBowlerBallType,
          ...(ball.bowlingAnalysis.pitchMap?.x &&
            ball.bowlingAnalysis.pitchMap?.y && {
              pitch_map: commonLocationXY(ball.bowlingAnalysis.pitchMap.x, ball.bowlingAnalysis.pitchMap?.y),
            }),
          bouncer: ball.bowlingAnalysis.getBallLengthType === 'BOUNCER',
          yorker: ball.bowlingAnalysis.getBallLengthType === 'YORKER',
        }),
      }),
      ...(ball.battingAnalysis &&
        ball.battingAnalysis.shots && {
          edge: ball.battingAnalysis.shots.getShotContactType.indexOf('_EDGE') > -1,
          play_and_miss: ball.battingAnalysis.shots.getShotContactType === 'PLAY_MISS',
        }),
      sport_event_state: commonMatchState(game),
    } as const
  }

  const commentary = (game: IMatchModel, ball: IBallModel, innings?: IInningModel, deleted?: boolean) => {
    let inn = innings
    if (!inn) {
      inn = game.getActiveInning(true)
    }
    if (!inn) return

    return {
      type: 'Commentary',
      language_tag: 'en-AU',
      commentary: ball.textDescription,
      for: 'EVENT',
      linked_event: {
        id: uuidV5(
          `${game.id}_${inn.inningsMatchOrder}_${ball.overNumber + 1}_${ball.ballNumber}_${commonBallMessageType}`,
          dataUUIDNamespace
        ),
      },
      deleted: deleted ?? false,
    } as const
  }

  const deadBall = (game: IMatchModel, ball: IBallModel, innings?: IInningModel) => {
    let inn = innings
    if (!inn) {
      inn = game.getActiveInning(true)
    }
    if (!inn) return

    return {
      type: 'Event.Sport.Cricket.DeadBall',
      bowling_event: {
        id: uuidV5(
          `${game.id}_${inn.inningsMatchOrder}_${ball.overNumber + 1}_${ball.ballNumber}_${commonBowlingMessageType}`,
          dataUUIDNamespace
        ),
      },
      sport_event_state: commonMatchState(game),
    } as const
  }

  const decisionReview = (
    status: string,
    reason: string,
    game: IMatchModel,
    ball: IBallModel,
    innings?: IInningModel,
    team?: IMatchTeamModel
  ) => {
    let inn = innings
    if (!inn) {
      inn = game.getActiveInning(true)
    }
    if (!inn) return

    return {
      type: 'Event.Sport.Cricket.DecisionReview',
      status: status,
      requested_by_competitor: team ? commonCompetitor(team) : undefined,
      reason: reason,
      innings_order: inn.inningsMatchOrder,
      over_number: ball.overNumber + 1,
      ball_number: ball.ballNumber,
      sport_event_state: commonMatchState(game),
    } as const
  }

  const dismissal = (
    game: IMatchModel,
    dismissalType: string,
    innings: IInningModel,
    perf: IBattingPerformanceModel | undefined,
    ball: IBallModel | undefined,
    ignoreMatchState?: boolean,
    deleted?: boolean
  ) => {
    const fielderIds = ball
      ? ball?.fieldingAnalysis?.fieldingPlayers?.reduce((acc: { fielder1_id?: string; fielder2_id?: string }, cur) => {
          return {
            ...acc,
            ...(cur.order === 1 && { fielder1_id: cur.playerMp.player.id }),
            ...(cur.order === 2 && { fielder2_id: cur.playerMp.player.id }),
          }
        }, {})
      : perf && perf.dismissal?.fielders
      ? perf.dismissal.fielders.reduce((acc: { fielder1_id?: string; fielder2_id?: string }, cur) => {
          return {
            ...acc,
            ...(cur.order === 1 && { fielder1_id: cur.player.player.id }),
            ...(cur.order === 2 && { fielder2_id: cur.player.player.id }),
          }
        }, {})
      : undefined

    const fowOverSplit = !isNil(perf?.dismissal?.fowOver) ? perf?.dismissal?.fowOver.split('.') : null
    const dismissalTypeFormatted = dismissalType
      .replace('RETIRED_NO', 'RETIRED_NOT_OUT')
      .replace('LBW', 'LEG_BEFORE_WICKET')

    return {
      type: 'Event.Sport.Cricket.Dismissal',
      dismissal_type: dismissalTypeFormatted,
      innings_order: innings.inningsMatchOrder,
      batting_competitor: commonCompetitor(innings.getBattingTeam),
      ...(!ignoreMatchState && {
        over_number: ball ? ball.overNumber + 1 : Number(innings.progressiveScores.oversBowled?.split('.')[0]) + 1,
      }),
      ...(innings.getBowlingTeam && {
        bowling_competitor: commonCompetitor(innings.getBowlingTeam),
        ...(innings.getBowlingTeam.getWicketkeeper && {
          wicket_keeper_id: innings.getBowlingTeam.getWicketkeeper.player.id,
        }),
      }),
      ...(ball
        ? {
            ball_number: ball.ballNumber,
            ...(ball.dismissal && {
              ...(ball.dismissal.batterMp && { batter_id: ball.dismissal.batterMp.player.id }),
              batters_crossed: ball.dismissal.battersCrossed,
              fall_of_wicket_number: ball.dismissal.wicketNumber,
              fall_of_wicket_runs: ball.dismissal.fowRuns,
              fall_of_wicket_overs: ball.dismissal.fowOver,
            }),
            ...(ball.bowlerMp && { bowler_id: ball.bowlerMp.player.id }),
            ...fielderIds,
          }
        : perf && {
            batter_id: perf.playerMp.player.id,
            ...(perf.dismissal && {
              ...(fowOverSplit &&
                ignoreMatchState && {
                  over_number: Number(fowOverSplit[0]) + 1,
                  ball_number: fowOverSplit.length > 1 ? Number(fowOverSplit[1]) : 1,
                }),
              batters_crossed: perf.dismissal.battersCrossed,
              fall_of_wicket_number: perf.dismissal.wicketNumber,
              fall_of_wicket_runs: perf.dismissal.fowRuns,
              fall_of_wicket_overs: perf.dismissal.fowOver,
              bowler_id: perf.dismissal.bowler?.player.id,
              ...fielderIds,
            }),
          }),
      ...(!ignoreMatchState && { sport_event_state: commonMatchState(game) }),
      deleted: deleted ?? false,
    } as const
  }

  const dls = (game: IMatchModel, isFirstBattingTeam: boolean) => {
    if (!game.matchDls || (!game.matchDls.targetScore && !isFirstBattingTeam)) return

    return {
      type: 'Event.Sport.Cricket.Shortened',
      ...(game.matchDls.targetOvers && { revised_overs: game.matchDls.targetOvers }),
      ...(!isFirstBattingTeam && { revised_target: game.matchDls.targetScore }),
      sport_event_state: commonMatchState(game),
    } as const
  }

  const fielding = (mode: ClspMode, ball: IBallModel, game: IMatchModel, innings?: IInningModel) => {
    let inn = innings
    if (!inn) {
      inn = game.getActiveInning(true)
    }
    if (!ball.fieldingAnalysis || !inn) return

    return {
      type: 'Event.Sport.Cricket.Fielding',
      innings_order: inn.inningsMatchOrder || undefined,
      over_number: ball.overNumber + 1,
      ball_number: ball.ballNumber,
      keeper_position: WicketKeeperPositionOptions[ball.fieldingAnalysis.wicketKeeperPositionId],
      fielded: ball.fieldingAnalysis.fielded,
      fielded_wicket_keeper: ball.fieldingAnalysis.fieldedWicketKeeper,
      misfielded: ball.fieldingAnalysis.misfielded,
      dropped_catch: ball.fieldingAnalysis.droppedCatch,
      run_out_missed: ball.fieldingAnalysis.runOutMissed,
      stumping_missed: ball.fieldingAnalysis.stumpingMissed,
      runs_saved: ball.fieldingAnalysis.runsSaved,
      over_throws: ball.fieldingAnalysis.overThrows,
      ...(ball.fieldingAnalysis.fieldingPlayers && {
        fielders: sortBy(ball.fieldingAnalysis.fieldingPlayers, ['order']).map(fielder => {
          return {
            fielder: fielder.playerMp.player.id,
            ...(mode !== 'core' && { difficulty_rating: fielder.difficultyRating ?? null }),
            ...(mode !== 'core' && { pressure_applied: fielder.pressure ?? false }),
          }
        }),
      }),
      ...(mode === 'fielding' &&
        ball.fieldingAnalysis.fieldingPositions && {
          field_placements: ball.fieldingAnalysis.fieldingPositions.map(position => {
            return {
              ...(position.playerMp && { fielder: position.playerMp.player.id }),
              location: commonLocationXY(position.placement.x, position.placement.y),
            }
          }),
        }),
      ...(mode !== 'fielding' && { sport_event_state: commonMatchState(game) }),
    } as const
  }

  const followOn = (teamFollowingOn: IMatchTeamModel, teamEnforcing: IMatchTeamModel, game: IMatchModel) => {
    return {
      type: 'Event.Sport.Cricket.FollowOn',
      competitor_to_follow_on: commonCompetitor(teamFollowingOn),
      competitor_enforcing: commonCompetitor(teamEnforcing),
      sport_event_state: commonMatchState(game),
    } as const
  }

  const freeHit = (game: IMatchModel) => {
    return {
      type: 'Event.Sport.Cricket.FreeHit',
      sport_event_state: commonMatchState(game),
    } as const
  }

  const heartbeat = () => {
    return {
      type: 'Event.Sport.Cricket.Heartbeat',
    } as const
  }

  const innings = (
    action: string,
    game: IMatchModel,
    innings?: IInningModel,
    finishReason?: string,
    ignoreMatchState?: boolean
  ) => {
    let inn = innings
    if (!inn) {
      inn = game.getActiveInning(true)
    }
    if (!inn) return

    const useFinishReason = commonInningsFinishReason(finishReason)
    const bowlingTeam = inn.getBowlingTeam

    return {
      type: 'Event.Sport.Cricket.Innings',
      action: action,
      innings_type: inn.superOver ? 'SUPEROVER' : 'NORMAL',
      innings_order: inn.inningsMatchOrder || 1,
      batting_competitor: commonCompetitor(inn.getBattingTeam),
      ...(bowlingTeam && { bowling_competitor: commonCompetitor(bowlingTeam) }),
      ...(useFinishReason && { finish_reason: useFinishReason }),
      ...(!ignoreMatchState && { sport_event_state: commonMatchState(game) }),
    } as const
  }

  const inningsState = (
    mode: ClspMode,
    innings: IInningModel,
    game: IMatchModel,
    balls: IBallStoreModel,
    finishReason?: string
  ) => {
    const ballsPerOver = game.matchConfigs.ballsPerOver || 6
    const lastBall = commonCurrentOversBowled(innings.progressiveScores.oversBowled, ballsPerOver)
    const battingTeam = innings.getBattingTeam
    const bowlingTeam = innings.getBowlingTeam
    if (!bowlingTeam) return

    const useFinishReason = commonInningsFinishReason(finishReason)

    return {
      type: 'State.Sport.Cricket.Statistics.SportEvent.Inning',
      data_range: 'FULL',
      valid_at: new Date().toISOString(),
      innings: innings.id,
      innings_order: innings.inningsMatchOrder,
      last_over_number: Number(lastBall[0]),
      last_ball_number: lastBall.length > 1 ? Number(lastBall[1]) : 0,
      ...(useFinishReason && { finish_reason: useFinishReason }),
      competitors: [
        {
          competitor: commonCompetitor(battingTeam),
          statistics: {
            batting_runs: innings.progressiveScores.runs,
            batting_wickets_lost: innings.progressiveScores.wickets,
            batting_fours: innings.battingPerformances.reduce((acc, perf) => {
              return acc + perf.allFours
            }, 0),
            batting_sixes: innings.battingPerformances.reduce((acc, perf) => {
              return acc + perf.allSixes
            }, 0),
            batting_penalty_runs: innings.progressiveScores.penaltyRuns,
            ...(innings.progressiveScores.oversBowled && {
              batting_run_rate: getRunRate({
                runs: innings.progressiveScores.runs || 0,
                oversBowled: innings.progressiveScores.oversBowled,
                ballsPerOver: ballsPerOver,
              }),
            }),
            ...(game.matchConfigs.maxOvers && {
              // overs, balls and reviews remaining only apply to limited overs matches
              batting_overs_remaining: getOversRemaining({
                oversBowled: innings.progressiveScores.oversBowled || '0',
                oversPerInnings: game.matchConfigs.maxOvers,
                ballsPerOver: ballsPerOver,
              }),
              batting_balls_remaining: getBallsRemaining({
                oversBowled: innings.progressiveScores.oversBowled || '0',
                oversPerInnings: game.matchConfigs.maxOvers,
                ballsPerOver: ballsPerOver,
              }),
              batting_reviews_remaining: innings.battingReviewsRemaining,
            }),
          },
          players: innings.battingPerformances.map(perf => {
            return {
              player: {
                id: perf.playerMp.player.id,
                name: perf.playerMp.getFullName,
              },
              statistics: {
                batting_order: perf.order,
                batting_runs: perf.allRuns,
                batting_balls_faced: perf.allBalls,
                batting_dot_balls: perf.allDotBalls,
                batting_ones: perf.allOnes,
                batting_twos: perf.allTwos,
                batting_threes: perf.allThrees,
                batting_fours: perf.allFours,
                batting_sixes: perf.allSixes,
                batting_strike_rate: perf.allStrikeRate,
                batting_minutes_at_crease: perf.allMins,
                batting_shots_attacking: perf.allAttackingShots,
                batting_shots_attacking_percentage:
                  perf.allBalls > 0 ? round((perf.allAttackingShots / perf.allBalls) * 100, 2) : 0,
                ...(perf.shotSides &&
                  mode === 'advanced' && {
                    batting_shots_off_side: perf.shotSides.offSide,
                    batting_shots_on_side: perf.shotSides.onSide,
                  }),
                ...(perf.shotZones &&
                  mode === 'advanced' && {
                    batting_shots_square_leg: perf.shotZones.squareLeg,
                    batting_shots_fine_leg: perf.shotZones.fineLeg,
                    batting_shots_third_man: perf.shotZones.thirdMan,
                    batting_shots_point: perf.shotZones.point,
                    batting_shots_cover: perf.shotZones.cover,
                    batting_shots_mid_off: perf.shotZones.midOff,
                    batting_shots_mid_on: perf.shotZones.midOn,
                    batting_shots_mid_wicket: perf.shotZones.midWicket,
                    batting_shots_long_on: perf.shotZones.longOn,
                    batting_shots_long_off: perf.shotZones.longOff,
                    batting_shots_deep_cover: perf.shotZones.deepCover,
                    batting_shots_deep_point: perf.shotZones.deepPoint,
                    batting_shots_slips: perf.shotZones.slips,
                    batting_shots_gully: perf.shotZones.gully,
                    batting_shots_deep_square_leg: perf.shotZones.deepSquareLeg,
                    batting_shots_deep_mid_wicket: perf.shotZones.deepMidWicket,
                  }),
                ...(mode === 'advanced' && {
                  batting_wagon_wheel:
                    balls
                      .getBallsFromPerf(innings.id, 'bat', perf)
                      ?.filter(fb => {
                        return (
                          fb.batterMp?.id === perf.playerMp.id &&
                          !isNil(fb.battingAnalysis?.shots.angle) &&
                          fb.battingAnalysis?.shots.length
                        )
                      })
                      .map(ball => {
                        return {
                          location: commonLocationRadial(
                            ball.battingAnalysis?.shots.angle || 0,
                            ball.battingAnalysis?.shots.length || 0
                          ),
                          runs_batter: ball.runsBat,
                        }
                      }) ?? [],
                }),
              },
            }
          }),
        },
        {
          competitor: commonCompetitor(bowlingTeam),
          statistics: {
            bowling_leg_byes: innings.progressiveScores.legByes,
            bowling_no_balls: innings.progressiveScores.noBalls,
            bowling_wides: innings.progressiveScores.wides,
            bowling_byes: innings.progressiveScores.byes,
            ...(game.matchConfigs.maxOvers && {
              // reviews remaining only applies to limited overs matches
              bowling_reviews_remaining: innings.bowlingReviewsRemaining,
            }),
          },
          players: innings.bowlingPerformances.map(perf => {
            return {
              player: {
                id: perf.playerMp.player.id,
                name: perf.playerMp.getFullName,
              },
              statistics: {
                bowling_order: perf.order,
                bowling_overs_bowled: perf.allOvers.toString(),
                bowling_maidens: perf.allMaidens,
                bowling_dot_balls: perf.allDotBalls,
                bowling_wickets: perf.allWickets,
                bowling_conceded_runs: perf.allRuns,
                bowling_wides: perf.allWides,
                bowling_no_balls: perf.allNoBalls,
                bowling_economy_rate: perf.allEcon(ballsPerOver),
                bowling_appeals: perf.allAppeals,
                bowling_around_the_wicket: perf.allAroundTheWicket,
                bowling_over_the_wicket: perf.allOverTheWicket,
                ...(mode === 'advanced' && {
                  bowling_yorkers: perf.allYorkers,
                }),
                ...(mode === 'advanced' && {
                  bowling_bouncers: perf.allBouncers,
                }),
                bowling_edges: perf.allEdges,
                bowling_play_and_misses: perf.allPlayAndMisses,
              },
            }
          }),
        },
      ],
    }
  }

  const inningsStatePartnerships = (innings: IInningModel, ballsPerOver?: number | null) => {
    const lastBall = commonCurrentOversBowled(innings.progressiveScores.oversBowled, ballsPerOver)

    return {
      type: 'State.Sport.Cricket.Statistics.SportEvent.InningBattingPartnerships',
      data_range: 'FULL',
      valid_at: new Date().toISOString(),
      innings: innings.id,
      innings_order: innings.inningsMatchOrder,
      last_over_number: Number(lastBall[0]),
      last_ball_number: lastBall.length > 1 ? Number(lastBall[1]) : 0,
      competitor: commonCompetitor(innings.getBattingTeam),
      partnerships:
        innings.partnerships?.map(p => {
          const dismissedBatter = p.partnershipPlayers.find(q => {
            return q.status === 1
          })
          const dismissedBatterPerf = dismissedBatter
            ? innings.battingPerformances.find(bp => {
                return bp.playerMp.id === dismissedBatter.playerMp
              })
            : null
          return {
            wicket_number: p.wicketNumber,
            batting_runs: p.totalRuns,
            batting_runs_extras: p.extraRuns,
            batting_balls_faced: p.balls,
            batting_minutes_at_crease: p.allMins(),
            start: p.start,
            start_time: p.startTimestamp,
            end: p.end,
            end_time: p.endTimestamp,
            players: p.partnershipPlayers.map(pp => {
              const batterPerf = innings.battingPerformances.find(bp => {
                return bp.playerMp.id === pp.playerMp
              })
              return {
                player: {
                  id: batterPerf?.playerMp.player.id ?? pp.playerMp,
                  name: pp.getDisplayName,
                },
                status: PartnershipStatusTypes[pp.status],
                batting_balls_faced: pp.balls,
                batting_dot_balls: pp.dots,
                batting_fours: pp.fours,
                batting_ones: pp.ones,
                batting_order: pp.battingOrder,
                batting_runs: pp.runs,
                batting_sixes: pp.sixes,
                batting_strike_rate: pp.strikeRate,
                batting_threes: pp.threes,
                batting_twos: pp.twos,
              }
            }),
            ...(dismissedBatterPerf && {
              dismissed_batter: dismissedBatterPerf.playerMp.player.id,
              ...(dismissedBatterPerf.dismissal && {
                fall_of_wicket_number: dismissedBatterPerf.dismissal.wicketNumber,
                fall_of_wicket_runs: dismissedBatterPerf.dismissal.fowRuns,
                fall_of_wicket_overs: dismissedBatterPerf.dismissal.fowOver,
              }),
            }),
          }
        }) ?? [],
    }
  }

  const inningsStateSpells = (mode: ClspMode, innings: IInningModel, ballsPerOver?: number | null) => {
    const lastBall = commonCurrentOversBowled(innings.progressiveScores.oversBowled, ballsPerOver)

    return {
      type: 'State.Sport.Cricket.Statistics.SportEvent.InningBowlingSpells',
      data_range: 'FULL',
      valid_at: new Date().toISOString(),
      innings: innings.id,
      innings_order: innings.inningsMatchOrder,
      last_over_number: Number(lastBall[0]),
      last_ball_number: lastBall.length > 1 ? Number(lastBall[1]) : 0,
      ...(innings.getBowlingTeam && { competitor: commonCompetitor(innings.getBowlingTeam) }),
      bowling_spells: flatten(
        innings.bowlingPerformances.map(perf => {
          return perf.bowlingPerformanceSpells.map(spell => {
            return {
              player: {
                id: perf.playerMp.player.id,
                name: perf.playerMp.getFullName,
              },
              spell_order: spell.instanceNumber,
              start: spell.start,
              start_time: spell.startTimestamp,
              end: spell.end,
              end_time: spell.endTimestamp,
              bowling_overs_bowled: spell.overs,
              bowling_maidens: spell.maidens,
              bowling_dot_balls: spell.dots,
              bowling_wickets: spell.wickets,
              bowling_conceded_runs: spell.runs,
              bowling_wides: spell.extras.wides,
              bowling_no_balls: spell.extras.noBalls,
              bowling_economy_rate: spell.econ(ballsPerOver || 6),
              bowling_appeals: spell.appeals,
              bowling_around_the_wicket: spell.aroundTheWicket,
              bowling_over_the_wicket: spell.overTheWicket,
              ...(mode === 'advanced' && {
                bowling_yorkers: spell.yorkers,
              }),
              ...(mode === 'advanced' && {
                bowling_bouncers: spell.bouncers,
              }),
              bowling_edges: spell.edges,
              bowling_play_and_misses: spell.playAndMisses,
            }
          })
        }) ?? []
      ),
    }
  }

  const lineup = (preMatch: boolean, teams: IMatchTeamModel[], officials?: IMatchOfficialModel[]) => {
    return {
      type: preMatch ? 'CRICKET_INITIAL' : 'CRICKET_IN_PLAY',
      competitors: teams.map((t: IMatchTeamModel) => {
        return {
          competitor: commonCompetitor(t),
          officials:
            t.matchStaff.map((s: IMatchStaffModel) => {
              const mtStaff: CompetitorOfficial = {
                id: s.person.id,
                name: s.getFullName,
              }
              if (!isNil(s.matchTeamRoleId)) {
                mtStaff.official_type = commonOfficialType(MatchTeamRoleOptions[s.matchTeamRoleId])
              }
              return mtStaff
            }) || [],
          players: t.matchPlayers.map((p: IMatchPlayerModel) => {
            const mtPlayer: CompetitorPlayer = {
              id: p.player.id,
              name: p.getFullName,
              substitute: p.substitute,
              active_status: p.activeStatus,
              captain: p.captain,
              vice_captain: p.viceCaptain,
              wicket_keeper: p.wicketKeeper,
            }
            if (!isNil(p.selectionNumber)) mtPlayer.selection_number = p.selectionNumber
            if (!isNil(p.shirtNumber)) mtPlayer.shirt_number = p.shirtNumber
            if (!isNil(p.getActiveReason)) mtPlayer.active_reason = p.getActiveReason
            if (p.getBowlingType) mtPlayer.bowling_type = p.getBowlingType
            if (p.getBowlingHanded) mtPlayer.bowling_hand = p.getBowlingHanded
            if (p.getBattingHanded) mtPlayer.batting_hand = p.getBattingHanded
            if (p.getPrimaryThrowingArm) mtPlayer.throwing_hand = p.getPrimaryThrowingArm
            return mtPlayer
          }),
        }
      }),
      officials:
        officials?.map((o: IMatchOfficialModel) => {
          return {
            id: o.official.id,
            name: o.getFullName,
            official_type: commonOfficialType(OfficialTypeOptions[o.matchOfficialTypeId]),
          }
        }) || [],
      valid_at: new Date().toISOString(),
    } as const
  }

  const manualScoreChange = (value: number, game: IMatchModel, batterId?: string, innings?: IInningModel) => {
    const batterFromId = batterId ? innings?.getBattingTeam.getPlayerById(batterId) : null

    return {
      type: 'Event.Sport.Cricket.ManualRunChange',
      value: value,
      ...(batterFromId && { batter_id: batterFromId.player.id }),
      ...(innings && { competitor: commonCompetitor(innings.getBattingTeam) }),
      sport_event_state: commonMatchState(game),
    } as const
  }

  const manualWicketChange = (value: number, game: IMatchModel, innings: IInningModel) => {
    return {
      type: 'Event.Sport.Cricket.ManualWicketChange',
      value: value,
      competitor: commonCompetitor(innings.getBattingTeam),
      sport_event_state: commonMatchState(game),
    } as const
  }

  const matchBreak = (action: string, reason: string | null, game: IMatchModel) => {
    const reasonFormatted = reason === 'RAIN_DELAY' ? 'WEATHER' : reason
    if (!reasonFormatted) return

    return {
      type: 'Event.Sport.Cricket.SportEventBreak',
      action: action,
      reason: reasonFormatted,
      sport_event_state: commonMatchState(game),
    } as const
  }

  const matchResult = (
    result: string,
    winningTeam: IMatchTeamModel | null,
    game: IMatchModel,
    mvpPlayerId?: string,
    isScoringManually?: boolean
  ) => {
    const endMatchResult = game.getWinningMarginAndResult(result, undefined, isScoringManually ?? false)
    const winningCompetitor = winningTeam ?? game.getTeamById(endMatchResult.winningTeam ?? '') ?? null
    const winningMarginRuns = endMatchResult.margin.inningsRuns ?? endMatchResult.margin.runs ?? null

    return {
      type: 'Event.Sport.Cricket.SportEventResult',
      result: result,
      winning_competitor: winningCompetitor ? commonCompetitor(winningCompetitor) : null,
      ...(!isNil(endMatchResult.margin.wickets) && { winning_margin_wickets: endMatchResult.margin.wickets }),
      ...(!isNil(winningMarginRuns) && { winning_margin_runs: winningMarginRuns }),
      ...(endMatchResult.margin.isDlsAffected && { winning_margin_shortened: true }),
      ...(!isNil(endMatchResult.margin.overs) && { overs_remaining: endMatchResult.margin.overs }),
      ...(!isNil(endMatchResult.margin.balls) && { balls_remaining: endMatchResult.margin.balls }),
      ...(endMatchResult.margin.isSuperOver && { super_over: true }),
      ...(mvpPlayerId && { most_valuable_player: mvpPlayerId }),
      sport_event_state: commonMatchState(game),
    } as const
  }

  const matchStatus = (status: string, game: IMatchModel, isBreakStumps?: boolean, ignoreMatchState?: boolean) => {
    return {
      type: 'Event.Sport.Cricket.SportEventStatus',
      status: commonMatchStatus(status, isBreakStumps),
      ...(!ignoreMatchState && { sport_event_state: commonMatchState(game) }),
    } as const
  }

  const newBall = (reason: string, game: IMatchModel) => {
    const reasonFormatted = reason === 'REPLACED_BALL' ? 'REPLACED' : reason
    return {
      type: 'Event.Sport.Cricket.NewBall',
      reason: reasonFormatted,
      sport_event_state: commonMatchState(game),
    } as const
  }

  const over = (
    action: string,
    game: IMatchModel,
    ball: IBallModel,
    powerPlay: string | undefined,
    newVenueEnd: boolean,
    innings?: IInningModel
  ) => {
    let inn = innings
    if (!inn) {
      inn = game.getActiveInning(true)
    }
    if (!inn) return

    return {
      type: 'Event.Sport.Cricket.Over',
      action: action,
      innings_order: inn.inningsMatchOrder,
      super_over: inn.superOver ? true : false,
      over_number: ball.overNumber + 1,
      new_ball: !isNil(ball.newBallTakenId),
      new_venue_end: newVenueEnd ?? true,
      ...(ball.bowlerMp && { bowler_id: ball.bowlerMp.player.id }),
      ...(ball.venueEnd && { venue_end: ball.venueEnd.name }),
      ...(ball.umpireControl && { controlling_umpire: ball.umpireControl.official.id }),
      ...(powerPlay && { power_play: powerPlay.replace('POWER_PLAY', 'POWERPLAY') }),
      sport_event_state: commonMatchState(game),
    } as const
  }

  const overState = (innings: IInningModel, ball: IBallModel): S3pPayload | undefined => {
    const battingTeam = innings.getBattingTeam
    const bowlingTeam = innings.getBowlingTeam
    if (!bowlingTeam) return

    const over = {
      runs: 0,
      wickets: innings.getAllDismissalsTotalForAnOver(ball.overNumber),
      dots: 0,
      ones: 0,
      twos: 0,
      threes: 0,
      fours: 0,
      sixes: 0,
      legByes: 0,
      byes: 0,
      wides: 0,
      noBalls: 0,
    }
    ball.getOver().forEach(b => {
      const isNoBall = (b.getExtraType?.indexOf('NO_BALL') ?? -1) > -1
      over.runs += (b.runsBat ?? 0) + (b.runsExtra ?? 0)
      over.dots += (!b.runsBat && !b.runsExtra) || (isNoBall && b.runsExtra === 1) ? 1 : 0
      over.ones += b.runsBat === 1 ? 1 : 0
      over.twos += b.runsBat === 2 ? 1 : 0
      over.threes += b.runsBat === 3 ? 1 : 0
      over.fours += b.runsBat === 4 ? 1 : 0
      over.sixes += b.runsBat === 6 ? 1 : 0
      over.noBalls += isNoBall ? 1 : 0
      over.wides += b.getExtraType === 'WIDE' ? b.runsExtra ?? 0 : 0
      over.legByes += isNoBall ? (b.runsExtra ?? 1) - 1 : 0
      over.byes += isNoBall ? (b.runsExtra ?? 1) - 1 : 0
    })

    return {
      type: 'State.Sport.Cricket.Statistics.SportEvent.Over',
      data_range: 'FULL',
      valid_at: new Date().toISOString(),
      innings: innings.id,
      innings_order: innings.inningsMatchOrder,
      over_number: ball.overNumber + 1,
      competitors: [
        {
          competitor: commonCompetitor(battingTeam),
          statistics: {
            batting_runs: over.runs,
            batting_wickets_lost: over.wickets,
            batting_dot_balls: over.dots,
            batting_ones: over.ones,
            batting_twos: over.twos,
            batting_threes: over.threes,
            batting_fours: over.fours,
            batting_sixes: over.sixes,
          },
        },
        {
          competitor: commonCompetitor(bowlingTeam),
          statistics: {
            bowling_conceded_runs: over.runs,
            bowling_wickets: over.wickets,
            bowling_dot_balls: over.dots,
            bowling_leg_byes: over.legByes,
            bowling_no_balls: over.noBalls,
            bowling_wides: over.wides,
            bowling_byes: over.byes,
            bowling_maidens: ball.overIsMaiden() ? 1 : 0,
          },
        },
      ],
    } as const
  }

  const penaltyRuns = (
    runs: number,
    game: IMatchModel,
    team: IMatchTeamModel,
    inningsOrder?: number,
    deleted?: boolean
  ) => {
    return {
      type: 'Event.Sport.Cricket.PenaltyRuns',
      runs: runs,
      innings_order: inningsOrder || undefined,
      competitor_awarded: commonCompetitor(team),
      sport_event_state: commonMatchState(game),
      deleted: deleted ?? false,
    } as const
  }

  const pitchConditions = (conditions: IMatchConditionsModel | undefined, game: IMatchModel) => {
    if (!conditions) return

    return {
      type: 'Event.Sport.Cricket.PitchConditions',
      ...(typeof conditions.pitchMoistureTypeId === 'number' && {
        moisture: PitchMoistureOptions[conditions.pitchMoistureTypeId],
      }),
      ...(typeof conditions.pitchGrassTypeId === 'number' && {
        grass_cover: PitchGrassOptions[conditions.pitchGrassTypeId],
      }),
      ...(typeof conditions.pitchQualityTypeId === 'number' && {
        quality: PitchQualityOptions[conditions.pitchQualityTypeId],
      }),
      ...(typeof conditions.outfieldSpeedTypeId === 'number' && {
        outfield: OutfieldSpeedOptions[conditions.outfieldSpeedTypeId],
      }),
      ...(typeof conditions.boundaryTypeId === 'number' && {
        boundary_position: BoundaryOptions[conditions.boundaryTypeId],
      }),
      sport_event_state: commonMatchState(game),
    } as const
  }

  const possibleBoundary = (game: IMatchModel) => {
    return {
      type: 'Event.Betting.Cricket.PossibleBoundary',
      sport_event_state: commonMatchState(game),
    } as const
  }

  const possibleWicket = (game: IMatchModel) => {
    return {
      type: 'Event.Betting.Cricket.PossibleWicket',
      sport_event_state: commonMatchState(game),
    } as const
  }

  const runs = (ball: IBallModel, extrasType: string | null, game: IMatchModel, innings: IInningModel) => {
    const wicketKeeper = innings.getBowlingTeam?.getWicketkeeper

    return {
      type: 'Event.Sport.Cricket.Runs',
      runs_total: (ball.runsBat || 0) + (ball.runsExtra || 0),
      boundary: ball.isBoundary,
      runs_short: ball.shortRun,
      runs_batter: ball.runsBat || 0,
      runs_extra: ball.runsExtra || 0,
      innings_order: innings.inningsMatchOrder,
      over_number: ball.overNumber + 1,
      ball_number: ball.ballNumber,
      ...(extrasType && { extras_type: extrasType }),
      ...(ball.batterMp && { batter_id: ball.batterMp.player.id }),
      ...(ball.bowlerMp && { bowler_id: ball.bowlerMp.player.id }),
      ...(wicketKeeper && { wicket_keeper_id: wicketKeeper.player.id }),
      sport_event_state: commonMatchState(game),
    } as const
  }

  const toss = (decision: string, game: IMatchModel) => {
    if (isNil(game.wonTossMatchTeamId)) return
    const tossWinner = game.getTeamById(game.wonTossMatchTeamId)
    if (!tossWinner) return

    return {
      type: 'Event.Sport.Cricket.Toss',
      winner: commonCompetitor(tossWinner),
      decision: decision,
      sport_event_state: commonMatchState(game),
    } as const
  }

  const weatherConditions = (conditions: IMatchConditionsModel | undefined, game: IMatchModel) => {
    if (!conditions) return

    return {
      type: 'Event.Sport.Cricket.WeatherConditions',
      ...(typeof conditions.rainTypeId === 'number' && {
        rain: WeatherRainOptions[conditions.rainTypeId],
      }),
      ...(typeof conditions.atmosTypeId === 'number' && {
        sky: WeatherAtmosOptions[conditions.atmosTypeId],
      }),
      ...(typeof conditions.temperatureTypeId === 'number' && {
        temperature_range: WeatherTemperatureOptions[conditions.temperatureTypeId],
      }),
      ...(typeof conditions.windTypeId === 'number' && {
        wind: WeatherWindOptions[conditions.windTypeId],
      }),
      sport_event_state: commonMatchState(game),
    } as const
  }

  const resendBallS3pMessages = (
    appMode: ClspMode,
    inning: IInningModel | undefined,
    game: IClspMatchModel,
    ball: IBallModel,
    batterPerformance: IBattingPerformanceModel | undefined
  ) => {
    if (appMode !== 'fielding' && inning) {
      db.createS3PMessage(
        S3PHelpers.metadata(appMode, game, inning),
        S3PHelpers.batting(appMode, ball, game, inning, true)
      )
      db.createS3PMessage(
        S3PHelpers.metadata(appMode, game, inning),
        S3PHelpers.runs(
          ball,
          !isNil(ball.extrasTypeId) ? Reference.ExtrasTypeOptions[ball.extrasTypeId] : null,
          game,
          inning
        )
      )
      if (ball.dismissal) {
        const dismissalType = ball.dismissal.getDismissalType
        db.createS3PMessage(
          S3PHelpers.metadata(appMode, game, inning),
          S3PHelpers.dismissal(
            game,
            dismissalType,
            inning,
            includes(['RUN_OUT', 'ABSENT'], dismissalType) ? batterPerformance : undefined,
            ball
          )
        )
      }
    }
  }

  const resendInningsStateS3pMessages = (
    appMode: ClspMode,
    game: IClspMatchModel,
    inning: IInningModel,
    balls: IBallStoreModel
  ) => {
    db.createS3PMessage(
      S3PHelpers.metadata(appMode, game, inning),
      S3PHelpers.inningsState(appMode, inning, game, balls)
    )
    db.createS3PMessage(
      S3PHelpers.metadata(appMode, game, inning),
      S3PHelpers.inningsStatePartnerships(inning, game.matchConfigs.ballsPerOver)
    )
    db.createS3PMessage(
      S3PHelpers.metadata(appMode, game, inning),
      S3PHelpers.inningsStateSpells(appMode, inning, game.matchConfigs.ballsPerOver)
    )
  }

  return {
    metadata,
    appeal,
    ball,
    batting,
    bowlerChange,
    bowling,
    commentary,
    deadBall,
    decisionReview,
    dismissal,
    dls,
    fielding,
    followOn,
    freeHit,
    heartbeat,
    innings,
    inningsState,
    inningsStatePartnerships,
    inningsStateSpells,
    lineup,
    manualScoreChange,
    manualWicketChange,
    matchBreak,
    matchResult,
    matchStatus,
    newBall,
    over,
    overState,
    penaltyRuns,
    pitchConditions,
    possibleBoundary,
    possibleWicket,
    runs,
    toss,
    weatherConditions,
    resendBallS3pMessages,
    resendInningsStateS3pMessages,
  }
})()

export default S3PHelpers
