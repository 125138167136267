import { Box, Flex } from '@chakra-ui/react'
import { indexOf } from 'lodash'
import { observer } from 'mobx-react-lite'

import { db } from '../../data/dexie/Database'
import { PitchGrassOptions, PitchMoistureOptions, PitchQualityOptions } from '../../data/reference'
import S3PHelpers from '../../helpers/s3pHelpers'
import type { PitchProps } from '../../types/props'
import { SimpleStateButton } from '../Buttons/SimpleStateButton'
import SubHeading from '../Headings/SubHeading'

export const Pitch = observer(({ game, mode, conditions }: PitchProps) => {
  const changeConditionsPitch = ({ value, target }: { value: string; target?: string | null }) => {
    if (!value || !target) return
    if (target === 'moisture') {
      game.setMoisture(value)
    }
    if (target === 'grass') {
      game.setGrass(value)
    }
    if (target === 'quality') {
      game.setQuality(value)
    }
    if (mode !== 'fielding') {
      db.createS3PMessage(S3PHelpers.metadata(mode, game), S3PHelpers.pitchConditions(conditions, game), true)
    }
  }

  return (
    <Box paddingTop="14px">
      <SubHeading text="Pitch Conditions" secondary />
      <SubHeading text="Moisture" icon={['far', 'tint']} tertiary />
      <Flex paddingBottom="14px" flexDirection={mode === 'advanced' ? 'row' : 'column'}>
        <Flex flex={1} direction="row" padding="4px 0">
          <SimpleStateButton
            onClick={changeConditionsPitch}
            value="VERY_DRY"
            target="moisture"
            isActive={conditions?.pitchMoistureTypeId === indexOf(PitchMoistureOptions, 'VERY_DRY')}
            isFlex
            isFirst
            data-testid="conditionsVeryDry"
          >
            Very Dry
          </SimpleStateButton>
          <SimpleStateButton
            onClick={changeConditionsPitch}
            value="DRY"
            target="moisture"
            isActive={conditions?.pitchMoistureTypeId === indexOf(PitchMoistureOptions, 'DRY')}
            isFlex
            isMiddle={mode === 'advanced'}
            isLast={mode !== 'advanced'}
            data-testid="conditionsDry"
          >
            Dry
          </SimpleStateButton>
        </Flex>
        <Flex flex={1} direction="row" padding="4px 0">
          <SimpleStateButton
            onClick={changeConditionsPitch}
            value="NORMAL"
            target="moisture"
            isActive={conditions?.pitchMoistureTypeId === indexOf(PitchMoistureOptions, 'NORMAL')}
            isFlex
            isMiddle={mode === 'advanced'}
            isFirst={mode !== 'advanced'}
            data-testid="conditionsNormal"
          >
            Normal
          </SimpleStateButton>
          <SimpleStateButton
            onClick={changeConditionsPitch}
            value="MOIST"
            target="moisture"
            isActive={conditions?.pitchMoistureTypeId === indexOf(PitchMoistureOptions, 'MOIST')}
            isFlex
            isLast
            data-testid="conditionsMoist"
          >
            Moist
          </SimpleStateButton>
        </Flex>
      </Flex>
      <SubHeading text="Grass Cover" icon={['far', 'seedling']} tertiary />
      <Box paddingBottom="14px">
        <Flex direction="row" padding="4px 0">
          <SimpleStateButton
            onClick={changeConditionsPitch}
            value="BARREN"
            target="grass"
            isActive={conditions?.pitchGrassTypeId === indexOf(PitchGrassOptions, 'BARREN')}
            isFlex
            isFirst
            data-testid="grassCoverBarren"
          >
            Barren
          </SimpleStateButton>
          <SimpleStateButton
            onClick={changeConditionsPitch}
            value="NORMAL"
            target="grass"
            isActive={conditions?.pitchGrassTypeId === indexOf(PitchGrassOptions, 'NORMAL')}
            isFlex
            isMiddle
            data-testid="grassCoverNormal"
          >
            Normal
          </SimpleStateButton>
          <SimpleStateButton
            onClick={changeConditionsPitch}
            value="GREEN"
            target="grass"
            isActive={conditions?.pitchGrassTypeId === indexOf(PitchGrassOptions, 'GREEN')}
            isFlex
            isLast
            data-testid="grassCoverGreen"
          >
            Green
          </SimpleStateButton>
        </Flex>
      </Box>
      <SubHeading text="Quality/Bounce" icon={['far', 'ruler-triangle']} tertiary />
      <Flex paddingBottom="14px" flexDirection={mode === 'advanced' ? 'row' : 'column'}>
        <Flex flex={1} direction="row" padding="4px 0">
          <SimpleStateButton
            onClick={changeConditionsPitch}
            value="NORMAL"
            target="quality"
            isActive={conditions?.pitchQualityTypeId === indexOf(PitchQualityOptions, 'NORMAL')}
            isFlex
            isFirst
            data-testid="bounceQualityNormal"
          >
            Normal
          </SimpleStateButton>
          <SimpleStateButton
            onClick={changeConditionsPitch}
            value="DETERIORATING"
            target="quality"
            isActive={conditions?.pitchQualityTypeId === indexOf(PitchQualityOptions, 'DETERIORATING')}
            isFlex
            isMiddle={mode === 'advanced'}
            isLast={mode !== 'advanced'}
            data-testid="bounceQualityDeteriorating"
          >
            Deteriorating
          </SimpleStateButton>
        </Flex>
        <Flex flex={1} direction="row" padding="4px 0">
          <SimpleStateButton
            onClick={changeConditionsPitch}
            value="SLOW"
            target="quality"
            isActive={conditions?.pitchQualityTypeId === indexOf(PitchQualityOptions, 'SLOW')}
            isFlex
            isMiddle={mode === 'advanced'}
            isFirst={mode !== 'advanced'}
            data-testid="bounceQualitySlow"
          >
            Slow
          </SimpleStateButton>
          <SimpleStateButton
            onClick={changeConditionsPitch}
            value="LOW"
            target="quality"
            isActive={conditions?.pitchQualityTypeId === indexOf(PitchQualityOptions, 'LOW')}
            isFlex
            isLast
            data-testid="bounceQualityLow"
          >
            Low
          </SimpleStateButton>
        </Flex>
      </Flex>
    </Box>
  )
})
