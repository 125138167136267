import type {
  BallModel,
  BallStoreModel,
  CurrentInning,
  IBallModel,
  IBattingPerformanceModel,
  IBowlingPerformanceModel,
} from '@clsplus/cls-plus-data-models'
import type { SnapshotOrInstance } from 'mobx-state-tree'
import { createContext, useContext, useEffect, useState } from 'react'

import type { CascadeEditProps, EditBallProps, UmpireEnds } from '../types'
import type { IClspMatchModel } from '../types/models'

type GameState = {
  inBall: boolean
  setInBall: React.Dispatch<React.SetStateAction<boolean>>
  endOver: boolean
  setEndOver: React.Dispatch<React.SetStateAction<boolean>>
  endInning: boolean
  setEndInning: React.Dispatch<React.SetStateAction<boolean>>
  closedInning: boolean
  setClosedInning: React.Dispatch<React.SetStateAction<boolean>>
  betweenOvers: boolean
  setBetweenOvers: React.Dispatch<React.SetStateAction<boolean>>
  awaitingFirstBallOfOver: boolean
  setAwaitingFirstBallOfOver: React.Dispatch<React.SetStateAction<boolean>>
  dismissal: boolean
  setDismissal: React.Dispatch<React.SetStateAction<boolean>>
  retired: string | undefined
  setRetired: React.Dispatch<React.SetStateAction<string | undefined>>
  nonBallDismissal: string | undefined
  setNonBallDismissal: React.Dispatch<React.SetStateAction<string | undefined>>
  mistake: boolean
  setMistake: React.Dispatch<React.SetStateAction<boolean>>
  changeBowler: boolean
  setChangeBowler: React.Dispatch<React.SetStateAction<boolean>>
  mistakeBowler: boolean
  setMistakeBowler: React.Dispatch<React.SetStateAction<boolean>>
  changeBowlerBallOver: IBallModel | null
  setChangeBowlerBallOver: React.Dispatch<React.SetStateAction<IBallModel | null>>
  changeFielder: string | null
  setChangeFielder: React.Dispatch<React.SetStateAction<string | null>>
  fieldHasChanged: boolean
  setFieldHasChanged: React.Dispatch<React.SetStateAction<boolean>>
  scorePassedIsOpen: boolean
  setScorePassedIsOpen: React.Dispatch<React.SetStateAction<boolean>>
  oversPassedIsOpen: boolean
  setOversPassedIsOpen: React.Dispatch<React.SetStateAction<boolean>>
  wicketsPassedIsOpen: boolean
  setWicketsPassedIsOpen: React.Dispatch<React.SetStateAction<boolean>>
  powerPlayStartIsOpen: boolean
  setPowerPlayStartIsOpen: React.Dispatch<React.SetStateAction<boolean>>
  venueEndsIsOpen: boolean
  setVenueEndsIsOpen: React.Dispatch<React.SetStateAction<boolean>>
  umpireEndsIsOpen: boolean
  setUmpireEndsIsOpen: React.Dispatch<React.SetStateAction<boolean>>
  umpireEnds: UmpireEnds | undefined
  setUmpireEnds: React.Dispatch<React.SetStateAction<UmpireEnds | undefined>>
  cascadeEditIsOpen: boolean
  setCascadeEditIsOpen: React.Dispatch<React.SetStateAction<boolean>>
  cascadeEditProps: CascadeEditProps | undefined
  setCascadeEditProps: React.Dispatch<React.SetStateAction<CascadeEditProps | undefined>>
  cascadeFromEditBallDismissed: boolean
  setCascadeFromEditBallDismissed: React.Dispatch<React.SetStateAction<boolean>>
  editBall: SnapshotOrInstance<typeof BallModel> | undefined
  setEditBall: React.Dispatch<React.SetStateAction<SnapshotOrInstance<typeof BallModel> | undefined>>
  editBallProps: EditBallProps | undefined
  setEditBallProps: React.Dispatch<React.SetStateAction<EditBallProps | undefined>>
  editBallPrimary: boolean
  setEditBallPrimary: React.Dispatch<React.SetStateAction<boolean>>
  batterPerformance: IBattingPerformanceModel | undefined
  setBatterPerformanceToChange: React.Dispatch<React.SetStateAction<IBattingPerformanceModel | undefined>>
  bowlerPerformance: IBowlingPerformanceModel | undefined
  setBowlerPerformanceToChange: React.Dispatch<React.SetStateAction<IBowlingPerformanceModel | undefined>>
  currentBall: IBallModel | undefined
  setCurrentBall: React.Dispatch<React.SetStateAction<IBallModel | undefined>>
  ballsSnapshot: SnapshotOrInstance<typeof BallStoreModel> | undefined
  setBallsSnapshot: React.Dispatch<React.SetStateAction<SnapshotOrInstance<typeof BallStoreModel> | undefined>>
  ballRunsVal: number | null
  setBallRunsVal: React.Dispatch<React.SetStateAction<number | null>>
  ballExtrasVal: string | null
  setBallExtrasVal: React.Dispatch<React.SetStateAction<string | null>>
  currentInning: CurrentInning | undefined
  setCurrentInning: React.Dispatch<React.SetStateAction<CurrentInning | undefined>>
  commentaryChanged: boolean
  setCommentaryChanged: React.Dispatch<React.SetStateAction<boolean>>
  cancelledDismissal: boolean
  setCancelledDismissal: React.Dispatch<React.SetStateAction<boolean>>
  insertingBall: boolean
  setInsertingBall: React.Dispatch<React.SetStateAction<boolean>>
  isMatchStartingModalOpen: boolean
  setIsMatchStartingModalOpen: React.Dispatch<React.SetStateAction<boolean>>
  isMatchStartingModalManual: boolean
  setIsMatchStartingModalManual: React.Dispatch<React.SetStateAction<boolean>>
  isMatchStartingModalConfirmed: number
  setIsMatchStartingModalConfirmed: React.Dispatch<React.SetStateAction<number>>
  matchStateReset: () => void
}

type GameStateProviderProps = {
  children: React.ReactNode
  game: IClspMatchModel
}

const GameStateContext = createContext<GameState | undefined>(undefined)

export function useGameState(): GameState {
  const context = useContext(GameStateContext)
  if (!context) {
    throw new Error('useGameState must be used within a GameStateProvider')
  }
  return context
}

export function GameStateProvider({ children, game }: GameStateProviderProps) {
  const [inBall, setInBall] = useState(false)
  const [endOver, setEndOver] = useState(false)
  const [endInning, setEndInning] = useState(false)
  const [closedInning, setClosedInning] = useState(false)
  const [betweenOvers, setBetweenOvers] = useState(false)
  const [awaitingFirstBallOfOver, setAwaitingFirstBallOfOver] = useState(true)
  const [dismissal, setDismissal] = useState(false)
  const [retired, setRetired] = useState<string | undefined>()
  const [nonBallDismissal, setNonBallDismissal] = useState<string | undefined>()
  const [mistake, setMistake] = useState(false)
  const [changeBowler, setChangeBowler] = useState(false)
  const [mistakeBowler, setMistakeBowler] = useState(false)
  const [changeBowlerBallOver, setChangeBowlerBallOver] = useState<IBallModel | null>(null)
  const [changeFielder, setChangeFielder] = useState<string | null>(null)
  const [fieldHasChanged, setFieldHasChanged] = useState(false)
  const [scorePassedIsOpen, setScorePassedIsOpen] = useState(false)
  const [oversPassedIsOpen, setOversPassedIsOpen] = useState(false)
  const [wicketsPassedIsOpen, setWicketsPassedIsOpen] = useState(false)
  const [powerPlayStartIsOpen, setPowerPlayStartIsOpen] = useState(false)
  const [venueEndsIsOpen, setVenueEndsIsOpen] = useState(false)
  const [umpireEndsIsOpen, setUmpireEndsIsOpen] = useState(false)
  const [umpireEnds, setUmpireEnds] = useState<UmpireEnds | undefined>()
  const [cascadeEditIsOpen, setCascadeEditIsOpen] = useState(false)
  const [cascadeEditProps, setCascadeEditProps] = useState<CascadeEditProps | undefined>()
  const [cascadeFromEditBallDismissed, setCascadeFromEditBallDismissed] = useState(false)
  const [editBall, setEditBall] = useState<SnapshotOrInstance<typeof BallModel> | undefined>()
  const [editBallProps, setEditBallProps] = useState<EditBallProps | undefined>()
  const [editBallPrimary, setEditBallPrimary] = useState(false)
  const [batterPerformance, setBatterPerformanceToChange] = useState<IBattingPerformanceModel | undefined>()
  const [bowlerPerformance, setBowlerPerformanceToChange] = useState<IBowlingPerformanceModel | undefined>()
  const [currentBall, setCurrentBall] = useState<IBallModel | undefined>()
  const [ballsSnapshot, setBallsSnapshot] = useState<SnapshotOrInstance<typeof BallStoreModel> | undefined>()
  const [ballRunsVal, setBallRunsVal] = useState<number | null>(null)
  const [ballExtrasVal, setBallExtrasVal] = useState<string | null>(null)
  const [currentInning, setCurrentInning] = useState<CurrentInning | undefined>()
  const [commentaryChanged, setCommentaryChanged] = useState(false)
  const [cancelledDismissal, setCancelledDismissal] = useState(false)
  const [insertingBall, setInsertingBall] = useState(false)
  const [isMatchStartingModalOpen, setIsMatchStartingModalOpen] = useState(false)
  const [isMatchStartingModalManual, setIsMatchStartingModalManual] = useState(false)
  const [isMatchStartingModalConfirmed, setIsMatchStartingModalConfirmed] = useState(0)

  useEffect(() => {
    // check if we are between/at the end of an innings/end of match
    // (i.e. no active innings, but we do have some innings, and we do have a recently-ended innings)
    if (
      !game?.getActiveInning(undefined, true) &&
      game?.getAllInningInOrder.length &&
      game?.getActiveInning(true) &&
      setClosedInning
    ) {
      setClosedInning(true)
    }
  }, [game, setClosedInning])

  const matchStateReset = () => {
    setInBall(false)
    setCancelledDismissal(false)
    setEndOver(false)
    setEndInning(false)
    setBetweenOvers(false)
    setClosedInning(false)
    setAwaitingFirstBallOfOver(false)
    setDismissal(false)
    setRetired(undefined)
    setNonBallDismissal(undefined)
    setMistake(false)
    setChangeBowler(false)
    setChangeFielder(null)
    setFieldHasChanged(false)
    setMistakeBowler(false)
    setChangeBowlerBallOver(null)
    setScorePassedIsOpen(false)
    setOversPassedIsOpen(false)
    setWicketsPassedIsOpen(false)
    setPowerPlayStartIsOpen(false)
    setVenueEndsIsOpen(false)
    setUmpireEndsIsOpen(false)
    setUmpireEnds(undefined)
    setCascadeEditIsOpen(false)
    setCascadeEditProps(undefined)
    setCascadeFromEditBallDismissed(false)
    setEditBall(undefined)
    setEditBallProps(undefined)
    setEditBallPrimary(false)
    setBatterPerformanceToChange(undefined)
    setBowlerPerformanceToChange(undefined)
    setCurrentBall(undefined)
    setBallsSnapshot(undefined)
    setBallRunsVal(null)
    setBallExtrasVal(null)
    setCurrentInning(undefined)
    setCommentaryChanged(false)
    setInsertingBall(false)
    setIsMatchStartingModalOpen(false)
    setIsMatchStartingModalManual(false)
    setIsMatchStartingModalConfirmed(0)
  }

  return (
    <GameStateContext.Provider
      value={{
        inBall,
        setInBall,
        endOver,
        setEndOver,
        endInning,
        setEndInning,
        closedInning,
        setClosedInning,
        betweenOvers,
        setBetweenOvers,
        awaitingFirstBallOfOver,
        setAwaitingFirstBallOfOver,
        dismissal,
        setDismissal,
        retired,
        setRetired,
        nonBallDismissal,
        setNonBallDismissal,
        mistake,
        setMistake,
        changeBowler,
        setChangeBowler,
        mistakeBowler,
        setMistakeBowler,
        changeBowlerBallOver,
        setChangeBowlerBallOver,
        changeFielder,
        setChangeFielder,
        fieldHasChanged,
        setFieldHasChanged,
        scorePassedIsOpen,
        setScorePassedIsOpen,
        oversPassedIsOpen,
        setOversPassedIsOpen,
        wicketsPassedIsOpen,
        setWicketsPassedIsOpen,
        powerPlayStartIsOpen,
        setPowerPlayStartIsOpen,
        venueEndsIsOpen,
        setVenueEndsIsOpen,
        umpireEndsIsOpen,
        setUmpireEndsIsOpen,
        umpireEnds,
        setUmpireEnds,
        cascadeEditIsOpen,
        setCascadeEditIsOpen,
        cascadeEditProps,
        setCascadeEditProps,
        cascadeFromEditBallDismissed,
        setCascadeFromEditBallDismissed,
        editBall,
        setEditBall,
        editBallProps,
        setEditBallProps,
        editBallPrimary,
        setEditBallPrimary,
        batterPerformance,
        setBatterPerformanceToChange,
        bowlerPerformance,
        setBowlerPerformanceToChange,
        currentBall,
        setCurrentBall,
        ballsSnapshot,
        setBallsSnapshot,
        ballRunsVal,
        setBallRunsVal,
        ballExtrasVal,
        setBallExtrasVal,
        currentInning,
        setCurrentInning,
        commentaryChanged,
        setCommentaryChanged,
        cancelledDismissal,
        setCancelledDismissal,
        insertingBall,
        setInsertingBall,
        isMatchStartingModalOpen,
        setIsMatchStartingModalOpen,
        isMatchStartingModalManual,
        setIsMatchStartingModalManual,
        isMatchStartingModalConfirmed,
        setIsMatchStartingModalConfirmed,
        matchStateReset,
      }}
    >
      {children}
    </GameStateContext.Provider>
  )
}
