import { Flex, Text } from '@chakra-ui/react'
import type { IMatchPlayerModel } from '@clsplus/cls-plus-data-models'
import { each, isNil } from 'lodash'
import { observer } from 'mobx-react-lite'
import { useCallback, useMemo } from 'react'
import type { Column, Row } from 'react-table'
import { useTable } from 'react-table'

import type { TextFieldCallbackArgs } from '../../components/TextField/TextField'
import TextField from '../../components/TextField/TextField'
import { BowlerTypeOptions, HandedTypeOptions, PlayerActiveReasonTypes } from '../../data/reference'
import { useMst } from '../../data/stores/rootStore'
import Theme from '../../theme/theme'
import type { DropdownCallbackArgs } from '../../types'
import type { ClspMode, TeamListProps } from '../../types/props'
import { Button } from '../Buttons/Button'
import { Checkbox } from '../Checkbox/Checkbox'
import Dropdown from '../Dropdown/Dropdown'

type TeamListTableProps = {
  columns: Column<IMatchPlayerModel>[]
  changePlayerOrder: (players: IMatchPlayerModel[], rowIndex: number, moveIndex: number) => void
  data: IMatchPlayerModel[]
  mode: ClspMode
}

type PlayerRowProps = {
  row: Row<IMatchPlayerModel>
  index: number
  totalRows: number
  moveRow: (rowIndex: number, direction: string) => void
  mode: ClspMode
}

type ChangePlayerFieldArgs = Omit<TextFieldCallbackArgs, 'value'> & {
  value: string | boolean
}

const TeamList = observer(
  ({ game, team, currentBall, mode, records, setRecords, setTeamsUpdated, isSetup = false }: TeamListProps) => {
    const { balls } = useMst()
    const activeInning = game.getActiveInning()

    const checkSub = (row: Row<IMatchPlayerModel>) => {
      const curActiveReason = row.original.getActiveReason
      if (curActiveReason?.endsWith('_OFF') || curActiveReason?.endsWith('_ON')) {
        return true
      }

      // Get all balls in the game
      const allBalls = balls.getAllBalls()

      // Use array.some to check if any player ball matches the conditions
      if (curActiveReason === 'STARTING_TEAM' || curActiveReason === 'SUB_UNUSED') {
        return allBalls.some(curBall => {
          return (
            row.original.player.id === curBall.batterMp?.player.id ||
            row.original.player.id === curBall.batterNonStrikeMp?.player.id ||
            row.original.player.id === curBall.bowlerMp?.player.id
          )
        })
      }
      return false
    }

    const onPlayerDetailsChange = useCallback(
      ({ id, target, value }: ChangePlayerFieldArgs) => {
        if (!id || !target) return
        const teamPlayers: IMatchPlayerModel[] | undefined = team?.getPlayersInBattingOrder()
        const player: IMatchPlayerModel | undefined = teamPlayers?.find(tm => tm.id === id)
        if (target === 'wicketKeeper') {
          // if we are setting a wicket keeper, we need to un-set any wicket keeper role...
          // ...that existed for another player in the same team (1 WK at a time!)
          const existingWk: IMatchPlayerModel | undefined = teamPlayers?.find(tm => tm.wicketKeeper)
          if (existingWk) {
            team?.setWicketKeeper(existingWk, false)
            if (mode === 'fielding' && currentBall && currentBall.fieldingAnalysis) {
              // fielding mode: swap the WK fielding placement on the current ball
              currentBall.fieldingAnalysis.swapPlayerPlacement(existingWk, player)
            }
            if (currentBall?.fieldingAnalysis?.fieldedWicketKeeper) {
              currentBall.setFieldedWicketkeeper(false)
              currentBall.setFielder(existingWk, true, true, true)
            }
          }
        }
        team?.updatePlayerField({ id, target, value }, player)

        // ensure the table refreshes by performing an immutability update (for example, a redundant splice & re-add)
        setRecords(team?.getPlayersInBattingOrder() || [])
        setTeamsUpdated(true)
      },
      [team, currentBall, setRecords, mode, setTeamsUpdated]
    )

    const onActiveReasonChange = useCallback(
      ({ id, target, value }: DropdownCallbackArgs) => {
        if (!id || !target || !value) return
        const teamPlayers: IMatchPlayerModel[] | undefined = team?.getPlayersInBattingOrder()
        const player = teamPlayers?.find(tm => tm.id === id)
        if (!player) return
        player.setActiveReasonId(PlayerActiveReasonTypes.indexOf(value))

        // cross-populate sub & activeStatus values if making changes during match setup
        if (isSetup && (value === 'SUB_UNUSED' || value === 'STARTING_TEAM')) {
          team?.updatePlayerField({ id: player.id, target: 'substitute', value: value === 'SUB_UNUSED' }, player)
          team?.updatePlayerField({ id: player.id, target: 'activeStatus', value: value === 'STARTING_TEAM' }, player)
        } else if (!isSetup) {
          if (value.endsWith('_ON')) {
            team?.updatePlayerField({ id: player.id, target: 'activeStatus', value: true }, player)
          } else if (value.endsWith('_OFF')) {
            team?.updatePlayerField({ id: player.id, target: 'activeStatus', value: false }, player)
          }
        }

        if (mode === 'fielding' && currentBall?.fieldingAnalysis) {
          if (value.endsWith('_OFF')) {
            // if we are subbing a current fielding player OFF the field, remove them from their placement
            const fielderCurrentPlacement = currentBall.fieldingAnalysis.fieldingPlacementForPlayer(player.id)
            if (fielderCurrentPlacement) fielderCurrentPlacement.removePlayer()
          } else if (value.endsWith('_ON')) {
            // if we are subbing a new fielder ON the field, check for any player-less placements and assign them
            const emptyPlacement = currentBall.fieldingAnalysis.fieldingPositions?.find(position => !position.playerMp)
            if (emptyPlacement) emptyPlacement.updatePlayer(player)
          }
        }

        // ensure the table refreshes by performing an immutability update (for example, a redundant splice & re-add)
        setRecords(team?.getPlayersInBattingOrder() || [])
        setTeamsUpdated(true)
      },
      [isSetup, team, mode, currentBall?.fieldingAnalysis, setRecords, setTeamsUpdated]
    )

    const onHandednessChange = useCallback(
      ({ id, target, value }: DropdownCallbackArgs) => {
        if (!id || !target || !value) return
        const teamPlayers: IMatchPlayerModel[] | undefined = team?.getPlayersInBattingOrder()
        const player: IMatchPlayerModel | undefined = teamPlayers?.find(tm => tm.id === id)

        if (target === 'bowlingHanded' || isNil(player?.player.bowlingHandedId)) {
          // if we changed bowling hand in any way, update the current ball & innings (if required)
          if (currentBall && currentBall.bowlerMp?.id === player?.id) currentBall?.setBowlerHand(value ?? null)
          const allBowlersBalls = balls.getBallsForBowler(activeInning?.id || '', player?.id || '')
          each(allBowlersBalls, ball => {
            if (!ball.bowlingAnalysis?.bowlerHandId) ball.setBowlerHand(value ?? null)
          })
        }
        if (target !== 'battingHanded' && isNil(player?.player.battingHandedId)) {
          team?.updatePlayerField({ id: `${id}`, target: 'battingHanded', value }, player)
        }
        if (target !== 'primaryThrowingArm' && isNil(player?.player.primaryThrowingArmId)) {
          team?.updatePlayerField({ id: `${id}`, target: 'primaryThrowingArm', value }, player)
        }
        if (target !== 'bowlingHanded' && isNil(player?.player.bowlingHandedId)) {
          team?.updatePlayerField({ id: `${id}`, target: 'bowlingHanded', value }, player)
        }

        if (target === 'bowlingType' || target === 'bowlingHanded') {
          // if we changed bowling type/handedness, update the current ball & innings (if required)
          if (currentBall && currentBall.bowlerMp?.id === player?.id) {
            if (target === 'bowlingType') {
              currentBall?.setBowlerType(value ?? null)
            }
            if (target === 'bowlingHanded') {
              currentBall?.setBowlerHand(value ?? null)
            }
          }
          const allBowlersBalls = balls.getBallsForBowler(activeInning?.id || '', player?.id || '')
          each(allBowlersBalls, ball => {
            if (target === 'bowlingType') {
              ball.setBowlerType(value ?? null)
            }
            if (target === 'bowlingHanded') {
              ball.setBowlerHand(value ?? null)
            }
          })
        }

        team?.updatePlayerField({ id: `${id}`, target, value }, player)
        setRecords(team?.getPlayersInBattingOrder() || [])
        setTeamsUpdated(true)
      },
      [team, currentBall, balls, activeInning, setRecords, setTeamsUpdated]
    )

    const changePlayerOrder = useCallback(
      (players: IMatchPlayerModel[], rowIndex: number, moveIndex: number) => {
        team?.setSelectionNumber(players[rowIndex], moveIndex + 1)
        team?.setSelectionNumber(players[moveIndex], rowIndex + 1)
        setRecords(team?.getPlayersInBattingOrder() || [])
        setTeamsUpdated(true)
      },
      [team, setRecords, setTeamsUpdated]
    )

    const columns: Column<IMatchPlayerModel>[] = useMemo(
      () => [
        {
          id: 'selectionNumber',
          accessor: (row: IMatchPlayerModel) => row.selectionNumber,
          Header: 'Bat #',
          Cell: ({ row }: { row: Row<IMatchPlayerModel> }) => (
            <Text
              h="40px"
              w="40px"
              borderRadius="20px"
              backgroundColor="cls.black"
              color="cls.white.400"
              textAlign="center"
              lineHeight="40px"
              fontWeight="bold"
              data-testid={`${row.original.id}_selectionNumber`}
            >
              {(row.index || 0) + 1 <= 11 ? (row.index || 0) + 1 : '-'}
            </Text>
          ),
        },
        {
          id: 'fullName',
          accessor: (row: IMatchPlayerModel) => row.getFullName,
          Header: 'Full Card Name',
          Cell: ({ row }: { row: Row<IMatchPlayerModel> }) => (
            <Text id={row.original.id} data-testid={`${row.original.id}_fullCardName`}>
              {row.original.getFullName}
            </Text>
          ),
        },
        {
          id: 'shirtNumber',
          accessor: (row: IMatchPlayerModel) => row.shirtNumber,
          Header: 'Shirt #',
          Cell: ({ row }: { row: Row<IMatchPlayerModel> }) => (
            <TextField
              type="number"
              id={row.original.id}
              target="shirtNumber"
              value={row.original.shirtNumber !== null ? `${row.original.shirtNumber}` : ''}
              min={0}
              width={70}
              onChange={onPlayerDetailsChange}
              data-testid={`${row.original.id}_shirtNumber`}
            />
          ),
        },
        ...(mode !== 'fielding'
          ? [
              {
                id: 'captain',
                accessor: (row: IMatchPlayerModel) => row.captain,
                Header: ' ',
                Cell: ({ row }: { row: Row<IMatchPlayerModel> }) => (
                  <Checkbox
                    isChecked={row.original.captain}
                    colorScheme="cls.yellow"
                    onChange={() => {
                      onPlayerDetailsChange({ id: row.original.id, target: 'captain', value: !row.original.captain })
                    }}
                    data-testid={`${row.original.id}_captainCheckbox`}
                  >
                    Capt.
                  </Checkbox>
                ),
              },
            ]
          : []),
        {
          id: 'wicketKeeper',
          accessor: (row: IMatchPlayerModel) => row.wicketKeeper,
          Header: ' ',
          Cell: ({ row }: { row: Row<IMatchPlayerModel> }) => (
            <Checkbox
              isChecked={row.original.wicketKeeper}
              colorScheme="cls.yellow"
              onChange={() => {
                onPlayerDetailsChange({
                  id: row.original.id,
                  target: 'wicketKeeper',
                  value: !row.original.wicketKeeper,
                })
              }}
              data-testid={`${row.original.id}_wicketKeeperCheckbox`}
            >
              WK
            </Checkbox>
          ),
        },
        {
          id: 'substitute',
          accessor: (row: IMatchPlayerModel) => row.substitute,
          Header: ' ',
          Cell: ({ row }: { row: Row<IMatchPlayerModel> }) => (
            <Checkbox
              isChecked={row.original.substitute}
              isDisabled={checkSub(row)}
              colorScheme="cls.yellow"
              onChange={() => {
                onPlayerDetailsChange({ id: row.original.id, target: 'substitute', value: !row.original.substitute })
                if (isSetup || !checkSub(row)) {
                  onActiveReasonChange({
                    id: row.original.id,
                    target: 'activeReason',
                    value: row.original.substitute ? 'SUB_UNUSED' : 'STARTING_TEAM',
                  })
                }
              }}
              data-testid={`${row.original.id}_substituteCheckbox`}
            >
              Sub
            </Checkbox>
          ),
        },
        {
          id: 'activeStatus',
          accessor: () => {
            return true
          },
          Header: 'Active',
          Cell: ({ row }: { row: Row<IMatchPlayerModel> }) => (
            <Flex align="center" justify="center">
              <Checkbox
                isChecked={row.original.activeStatus}
                isDisabled={isSetup}
                colorScheme="cls.yellow"
                onChange={() => {
                  onPlayerDetailsChange({
                    id: row.original.id,
                    target: 'activeStatus',
                    value: !row.original.activeStatus,
                  })
                }}
                data-testid={`${row.original.id}_activeStatusCheckbox`}
              />
            </Flex>
          ),
        },
        {
          id: 'activeReason',
          accessor: (row: IMatchPlayerModel) => row.getBattingHanded,
          Header: 'Status',
          Cell: ({ row }: { row: Row<IMatchPlayerModel> }) => (
            <Dropdown
              id={row.original.id}
              target="activeReason"
              options={PlayerActiveReasonTypes.filter(reason => reason !== 'UNKNOWN').map(value => {
                return {
                  value: value,
                  disabled: (() => {
                    if (isSetup && (value.endsWith('_OFF') || value.endsWith('_ON'))) {
                      return true
                    }

                    // Get all balls in the game
                    const allBalls = balls.getAllBalls()

                    // Use array.some to check if any player ball matches the conditions
                    if (value === 'STARTING_TEAM' || value === 'SUB_UNUSED') {
                      return allBalls.some(curBall => {
                        return (
                          row.original.player.id === curBall.batterMp?.player.id ||
                          row.original.player.id === curBall.batterNonStrikeMp?.player.id ||
                          row.original.player.id === curBall.bowlerMp?.player.id
                        )
                      })
                    }
                    return false
                  })(),
                }
              })}
              value={row.original.getActiveReason ?? ''}
              onChange={onActiveReasonChange}
              hideCheck
              data-testid={`${row.original.id}_activeReasonDropdown`}
            />
          ),
        },
        ...(mode === 'advanced'
          ? [
              {
                id: 'battingHanded',
                accessor: (row: IMatchPlayerModel) => row.getBattingHanded,
                Header: 'Batting Hand',
                Cell: ({ row }: { row: Row<IMatchPlayerModel> }) => (
                  <Dropdown
                    id={row.original.id}
                    target="battingHanded"
                    options={HandedTypeOptions.map(option => ({ value: option }))}
                    value={row.original.getBattingHanded ?? ''}
                    onChange={onHandednessChange}
                    hideCheck
                    data-testid={`${row.original.id}_battingHandDropdown`}
                  />
                ),
              },
              {
                id: 'bowlingHanded',
                accessor: (row: IMatchPlayerModel) => row.getBowlingHanded,
                Header: 'Bowling Hand',
                Cell: ({ row }: { row: Row<IMatchPlayerModel> }) => (
                  <Dropdown
                    id={row.original.id}
                    target="bowlingHanded"
                    options={HandedTypeOptions.map(option => ({ value: option }))}
                    value={row.original.getBowlingHanded ?? ''}
                    onChange={onHandednessChange}
                    hideCheck
                    data-testid={`${row.original.id}_bowlingHandDropdown`}
                  />
                ),
              },
              {
                id: 'bowlingType',
                accessor: (row: IMatchPlayerModel) => row.getBowlingType,
                Header: 'Bowling Type',
                Cell: ({ row }: { row: Row<IMatchPlayerModel> }) => (
                  <Dropdown
                    id={row.original.id}
                    target="bowlingType"
                    options={BowlerTypeOptions.map(option => ({ value: option }))}
                    value={row.original.getBowlingType ?? ''}
                    onChange={onHandednessChange}
                    hideCheck
                    data-testid={`${row.original.id}_bowlingTypeDropdown`}
                  />
                ),
              },
              {
                id: 'primaryThrowingArm',
                accessor: (row: IMatchPlayerModel) => row.getPrimaryThrowingArm,
                Header: 'Throwing Arm',
                Cell: ({ row }: { row: Row<IMatchPlayerModel> }) => (
                  <Dropdown
                    id={row.original.id}
                    target="primaryThrowingArm"
                    options={HandedTypeOptions.map(option => ({ value: option }))}
                    value={row.original.getPrimaryThrowingArm ?? ''}
                    onChange={onHandednessChange}
                    hideCheck
                    data-testid={`${row.original.id}_primaryThrowingArmDropdown`}
                  />
                ),
              },
            ]
          : []),
      ],
      [onPlayerDetailsChange, onHandednessChange, onActiveReasonChange, mode, isSetup]
    )

    if (!team) return null

    return <TeamListTable columns={columns} data={records} changePlayerOrder={changePlayerOrder} mode={mode} />
  }
)

const TeamListTable = ({ columns, changePlayerOrder, data, mode }: TeamListTableProps) => {
  const getRowId = useCallback((row: IMatchPlayerModel) => {
    return row.id
  }, [])

  const { getTableProps, getTableBodyProps, headerGroups, rows, prepareRow } = useTable({
    data,
    columns,
    getRowId,
  })

  const moveRow = (rowIndex: number, direction: string) => {
    changePlayerOrder(data, rowIndex, direction === 'up' ? rowIndex - 1 : rowIndex + 1)
  }

  return (
    <table {...getTableProps()} style={{ width: '100%' }}>
      <thead>
        {headerGroups.map(headerGroup => (
          // eslint-disable-next-line react/jsx-key
          <tr {...headerGroup.getHeaderGroupProps()} style={{ backgroundColor: Theme.colors.cls.backgroundGray }}>
            <th style={{ minWidth: '80px' }}></th>
            {headerGroup.headers.map(column => (
              // eslint-disable-next-line react/jsx-key
              <th
                {...column.getHeaderProps()}
                style={{ textAlign: 'left', padding: '10px', fontWeight: 'normal', fontStyle: 'italic' }}
              >
                {column.render('Header')}
              </th>
            ))}
          </tr>
        ))}
      </thead>
      <tbody {...getTableBodyProps()}>
        {rows.map((row, index) => {
          prepareRow(row)
          return (
            // eslint-disable-next-line react/jsx-key
            <PlayerRow
              index={index}
              totalRows={rows.length}
              row={row}
              moveRow={moveRow}
              mode={mode}
              {...row.getRowProps()}
            />
          )
        })}
      </tbody>
    </table>
  )
}

const PlayerRow = ({ row, index, totalRows, moveRow, mode }: PlayerRowProps) => {
  return (
    <tr
      style={{
        borderTop: `solid 1px ${Theme.colors.cls.backgroundGray}`,
      }}
    >
      <td style={{ minWidth: '80px', padding: mode === 'advanced' ? '0 7px' : '0 2px', textAlign: 'center' }}>
        {index > 0 && (
          <Button onClick={() => moveRow(index, 'up')} marginX="2px" size="xs" data-testid="moveRowUpButton">
            ▲
          </Button>
        )}
        {index + 1 < totalRows && (
          <Button onClick={() => moveRow(index, 'down')} marginX="2px" size="xs" data-testid="moveRowDownButton">
            ▼
          </Button>
        )}
      </td>
      {row.cells.map(cell => {
        return (
          // eslint-disable-next-line react/jsx-key
          <td
            {...cell.getCellProps()}
            style={{
              padding: '10px',
              borderLeft: cell.column.id === 'activeStatus' ? `solid 1px ${Theme.colors.cls.backgroundGray}` : 'none',
              borderRight:
                mode === 'advanced' && cell.column.id === 'activeReason'
                  ? `solid 1px ${Theme.colors.cls.backgroundGray}`
                  : 'none',
            }}
          >
            {cell.render('Cell')}
          </td>
        )
      })}
    </tr>
  )
}

export default TeamList
