import type {
  IBallModel,
  IBallStoreModel,
  IBowlingAnalysisModel,
  IBowlingPerformanceModel,
  IFieldingPlacementModel,
  IFieldingPositionModel,
  IInningModel,
  IMatchSettingsModel,
  IMatchTeamModel,
} from '@clsplus/cls-plus-data-models'
import { BallInningModel, FieldingPositionModel } from '@clsplus/cls-plus-data-models'
import { getOversValue } from '@clsplus/cricket-logic'
import { capitalize, flatten, includes, indexOf, isNil, map, orderBy } from 'lodash'
import type { SnapshotOrInstance } from 'mobx-state-tree'
import { getRoot } from 'mobx-state-tree'
import { v4 as uuid } from 'uuid'

import { RequestHandler } from '../data/api/RequestHandler'
import type { DBInningsBalls } from '../data/dexie/Database'
import { db } from '../data/dexie/Database'
import * as Reference from '../data/reference'
import type { IRootStore } from '../data/stores/rootStore'
import type { BallNumberTypes } from '../types'
import type { IClspMatchModel } from '../types/models'
import Auth from './auth'
import { ballDataCleaner, overIdPatcher } from './dataHelpers'

const STATIC_BALL_NUMBERS = [20, 19, 18, 17, 16, 15, 14, 13, 12, 11, 10, 9, 8, 7, 6, 5, 4, 3, 2, 1]

const BallHelpers = (function () {
  const bowlingDetails = (
    bowler: IBowlingPerformanceModel,
    previousBall: IBallModel | undefined,
    lastBallForBowler?: IBallModel | undefined
  ): IBowlingAnalysisModel => {
    const bowlerProfile = bowler.getPlayerProfile

    if (previousBall && previousBall.bowlerMp?.id === bowler.playerMp.id && previousBall.bowlingAnalysis) {
      return {
        id: uuid(),
        bowlerBallApproachId: previousBall.bowlingAnalysis.bowlerBallApproachId,
        bowlerHandId: previousBall.bowlingAnalysis.bowlerHandId,
        bowlerTypeId: previousBall.bowlingAnalysis.bowlerTypeId,
        bowlerBallTypeId: indexOf(Reference.BowlerBallTypeOptions, 'STOCK'),
        ballLengthTypeId: null,
        pitchMap: null,
        ballSpeed: null,
        spinRate: null,
        getBowlerHandType: '',
        getBowlerType: '',
        getBowlerBallType: '',
        getBowlerApproachType: '',
        getBallLengthType: '',
      }
    }

    // default
    return {
      id: uuid(),
      bowlerBallApproachId:
        lastBallForBowler && lastBallForBowler.bowlingAnalysis
          ? lastBallForBowler.bowlingAnalysis?.bowlerBallApproachId
          : indexOf(Reference.BowlerApproachOptions, 'OVER'),
      bowlerHandId: bowlerProfile
        ? bowlerProfile.player.bowlingHandedId
        : indexOf(Reference.HandedTypeOptions, 'RIGHT'),
      bowlerTypeId: bowlerProfile ? bowlerProfile.player.bowlingTypeId : indexOf(Reference.BowlerTypeOptions, 'SLOW'),
      bowlerBallTypeId: indexOf(Reference.BowlerBallTypeOptions, 'STOCK'),
      ballLengthTypeId: null,
      pitchMap: null,
      ballSpeed: null,
      spinRate: null,
      getBowlerHandType: '',
      getBowlerType: '',
      getBowlerBallType: '',
      getBowlerApproachType: '',
      getBallLengthType: '',
    }
  }

  const fieldingDetails = (
    bowler: IBowlingPerformanceModel | undefined,
    previousBall: IBallModel | undefined,
    matchSettings: IMatchSettingsModel,
    batterHanded?: string | null | undefined,
    fieldingTeam?: IMatchTeamModel | undefined,
    fieldingPositions?: SnapshotOrInstance<IFieldingPlacementModel>[] | undefined,
    lastBallForBowler?: IBallModel | undefined
  ) => {
    const bowlerProfile = bowler?.getPlayerProfile
    const assignedFielders: string[] = []

    const fieldingPlacementPlayer = (fieldingPositionId?: number | null) => {
      const wicketkeeper = fieldingTeam?.getWicketkeeper
      if (fieldingTeam && bowler && wicketkeeper) {
        // Fielding mode only
        if (!isNil(fieldingPositionId) && Reference.FieldingPositions[fieldingPositionId] === 'BOWLER' && bowler) {
          assignedFielders.push(bowler.playerMp.id)
          return bowler?.playerMp.id
        }
        if (
          !isNil(fieldingPositionId) &&
          Reference.FieldingPositions[fieldingPositionId] === 'WICKET_KEEPER' &&
          wicketkeeper
        ) {
          assignedFielders.push(wicketkeeper.id)
          return wicketkeeper.id || null
        }

        const fieldingPlayer = orderBy(fieldingTeam.matchPlayers, 'selectionNumber', 'asc').find(player => {
          if (
            indexOf(assignedFielders, player.id) === -1 &&
            player.id !== bowler.playerMp.id &&
            player.id !== wicketkeeper.id
          ) {
            assignedFielders.push(player.id)
            return player.id
          }
        })

        return fieldingPlayer?.id || null
      }
      return null
    }

    const defaultKeeperPosition = () => {
      if (
        bowlerProfile &&
        bowlerProfile.player.bowlingTypeId !== null &&
        (bowlerProfile.player.bowlingTypeId === indexOf(Reference.BowlerTypeOptions, 'WRIST_SPIN') ||
          bowlerProfile.player.bowlingTypeId === indexOf(Reference.BowlerTypeOptions, 'FINGER_SPIN'))
      ) {
        return 'UP'
      }
      return 'BACK'
    }

    const wicketKeeperPosition =
      lastBallForBowler &&
      lastBallForBowler.fieldingAnalysis &&
      !isNil(lastBallForBowler.fieldingAnalysis.wicketKeeperPositionId)
        ? lastBallForBowler.fieldingAnalysis?.wicketKeeperPositionId
        : indexOf(Reference.WicketKeeperPositionOptions, defaultKeeperPosition())

    if (
      fieldingPositions ||
      (previousBall &&
        previousBall.fieldingAnalysis &&
        previousBall.fieldingAnalysis.fieldingPositions &&
        previousBall.fieldingAnalysis.fieldingPositions.length > 0)
    ) {
      const fielders =
        fieldingPositions ||
        previousBall?.fieldingAnalysis?.fieldingPositions?.map((fp: IFieldingPositionModel) => {
          let fpid = fp.fieldingPositionId || null
          let pxy = { x: fp.placement.x, y: fp.placement.y }
          let placementMatch = null
          if (
            previousBall.bowlerMp?.id !== bowler?.playerMp.id &&
            fp.playerMp &&
            fp.playerMp.id === previousBall.bowlerMp?.id
          ) {
            // bowler has changed, this player was bowling, so set them to the new bowler's old position (i.e. a swap)
            placementMatch = previousBall.fieldingAnalysis?.fieldingPositions?.find((p: IFieldingPositionModel) => {
              return p.playerMp && p.playerMp.id === bowler?.playerMp.id
            })
            if (placementMatch) {
              fpid = placementMatch.fieldingPositionId
              pxy = { x: placementMatch.placement.x, y: placementMatch.placement.y }
            }
          } else if (
            previousBall.bowlerMp?.id !== bowler?.playerMp.id &&
            fp.playerMp &&
            fp.playerMp.id === bowler?.playerMp.id
          ) {
            // bowler has changed, this player is now bowling, so set them to the bowler position
            placementMatch = previousBall.fieldingAnalysis?.fieldingPositions?.find((p: IFieldingPositionModel) => {
              return p.playerMp && p.playerMp.id === previousBall.bowlerMp?.id
            })
            if (placementMatch) {
              fpid = placementMatch.fieldingPositionId
              pxy = { x: placementMatch.placement.x, y: placementMatch.placement.y }
            }
          }
          if (!isNil(fp.fieldingPositionId) && Reference.FieldingPositions[fp.fieldingPositionId] === 'WICKET_KEEPER') {
            // need to set wicketkeeper coords back to where they were for the last ball for this bowler -or- to the default value
            pxy = {
              x: Reference.WicketKeeperPositionCoords[wicketKeeperPosition].x,
              y: Reference.WicketKeeperPositionCoords[wicketKeeperPosition].y,
            }
          }
          return FieldingPositionModel.create({
            id: uuid(),
            playerMp: fieldingTeam ? fp.playerMp?.id : null,
            fieldingPositionId: fpid,
            placement: {
              id: uuid(),
              x: pxy.x,
              y: pxy.y,
            },
          })
        })
      return {
        id: uuid(),
        wicketKeeperPositionId: wicketKeeperPosition,
        fieldingPositions: matchSettings.fielderPlacement && fielders ? fielders : null,
        fielded: false,
        fieldedWicketKeeper: false,
        misfielded: false,
        droppedCatch: false,
        runOutMissed: false,
        stumpingMissed: false,
        wicketOpportunity: false,
        runsSaved: 0,
        overThrows: 0,
        fieldingPlayers: null,
      }
    }

    const defaultFielders = (
      fieldingTeam
        ? Reference.DefaultFieldingPlacements
        : batterHanded === 'LEFT'
        ? Reference.DefaultFieldingPlacementsLHB
        : Reference.DefaultFieldingPlacementsRHB
    ).map(coords => {
      return FieldingPositionModel.create({
        id: uuid(),
        playerMp: fieldingTeam ? fieldingPlacementPlayer(coords.fieldingPositionId) : null,
        fieldingPositionId: coords.fieldingPositionId || null,
        placement: {
          id: uuid(),
          x: coords.placement.x,
          y: coords.placement.y,
        },
      })
    })
    return {
      id: uuid(),
      wicketKeeperPositionId: wicketKeeperPosition,
      fieldingPositions: matchSettings.fielderPlacement && defaultFielders ? defaultFielders : null,
      fielded: false,
      fieldedWicketKeeper: false,
      misfielded: false,
      droppedCatch: false,
      runOutMissed: false,
      stumpingMissed: false,
      wicketOpportunity: false,
      runsSaved: 0,
      overThrows: 0,
      fieldingPlayers: null,
    }
  }

  const battingDetails = () => {
    return {
      id: uuid(),
      footworkTypeId: null,
      arrival: null,
      shots: {
        id: uuid(),
        attacking: false,
        inTheAir: false,
        throughField: false,
        shotTypeId: indexOf(Reference.ShotTypeOptions, 'UNKNOWN'),
        shotContactId: null,
        distance: null,
        wagonWheel: null,
      },
    }
  }

  const extrapolateReviewResult = (ball: IBallModel) => {
    if (!ball.review) return { removeReview: false, reviewingTeam: null }
    // bowling team reviews
    if (ball.review.originalDecision === false) {
      if (ball.review.finalDecision === false) return { removeReview: true, reviewingTeam: 'BOWLING' }
      // else
      return { removeReview: false, reviewingTeam: 'BOWLING' }
    }

    if (ball.review.originalDecision === true) {
      if (ball.review.finalDecision === true) return { removeReview: true, reviewingTeam: 'BATTING' }
      // else
      return { removeReview: false, reviewingTeam: 'BATTING' }
    }
    return { removeReview: false, reviewingTeam: null }
  }

  const predictiveDetails = () => {
    return {
      id: uuid(),
      likelyBoundary: false,
      likelyWicket: false,
      likelyRuns: false,
    }
  }

  const getExtraTypeForBall = (extraTypeId: number) => {
    return Reference.ExtrasTypeOptions[extraTypeId]
  }

  const calculateBallNumberInnings = (
    previousBall: IBallModel | undefined,
    endOfOver: boolean,
    ballsPerOver: number
  ): BallNumberTypes => {
    if (previousBall && previousBall.ballDisplayNumber !== null) {
      if (
        !endOfOver &&
        !isNil(previousBall.extrasTypeId) &&
        includes(['NO_BALL', 'WIDE', 'NO_BALL_LEG_BYE', 'NO_BALL_BYE'], getExtraTypeForBall(previousBall.extrasTypeId))
      ) {
        // do not increment display number
        return {
          overNumber: previousBall.overNumber,
          ballNumber: previousBall.ballNumber + 1,
          ballDisplayNumber: previousBall.ballDisplayNumber,
        }
      }

      const valueFromLib = String(
        getOversValue({
          currentValue: `${previousBall.overNumber}.${previousBall.ballDisplayNumber}`,
          ballDisplayNum: previousBall.ballDisplayNumber,
          endOver: endOfOver,
          ballsPerOver: ballsPerOver,
        })
      )
      const values = valueFromLib.split('.')
      return {
        overNumber: Number(values[0]),
        ballNumber: !values[1] || values[1] === '0' ? 1 : previousBall.ballNumber + 1,
        ballDisplayNumber: Number(values[1] || 1),
      }
    }
    return {
      overNumber: 0,
      ballNumber: 1,
      ballDisplayNumber: 1,
    }
  }

  const calculateBallNumberBowler = (
    ballDisplayNumber: number | null,
    endOfOver: boolean,
    currentValue: number,
    ballsPerOver: number,
    ballExtrasTypeId?: number | null,
    undo?: boolean,
    partialOver?: boolean,
    complete?: boolean,
    incompleteOverComplete?: boolean,
    ballEditedExtraDidNotChange?: boolean
  ): number => {
    if (ballDisplayNumber) {
      if (
        (ballDisplayNumber > ballsPerOver && !endOfOver && !partialOver) ||
        (!isNil(ballExtrasTypeId) &&
          (undo || (!undo && !complete) || (!undo && complete && ballEditedExtraDidNotChange)) &&
          includes(['NO_BALL', 'WIDE', 'NO_BALL_LEG_BYE', 'NO_BALL_BYE'], getExtraTypeForBall(ballExtrasTypeId)))
      ) {
        return currentValue
      }

      if (undo) {
        const valueFromLib = String(
          getOversValue({
            currentValue: `${currentValue}`,
            ballsPerOver: ballsPerOver,
            undo: undo,
          })
        )
        return Number(valueFromLib)
      }

      if (partialOver || incompleteOverComplete) {
        if (ballDisplayNumber > ballsPerOver) {
          return currentValue
        }
        const valueFromLib = String(
          getOversValue({
            currentValue: `${currentValue}`,
            // ballDisplayNum: ballDisplayNumber,
            endOver: endOfOver,
            ballsPerOver: ballsPerOver,
            isBowler: true,
          })
        )
        return Number(valueFromLib)
      } else {
        const valueFromLib = String(
          getOversValue({
            currentValue: `${currentValue}`,
            ballDisplayNum: ballDisplayNumber,
            endOver: endOfOver,
            ballsPerOver: ballsPerOver,
            isBowler: true,
          })
        )
        return Number(valueFromLib)
      }
    }
    return currentValue + 0.1
  }

  const incompleteBallData = (ball: IBallModel) => {
    let returner: string[] = []
    if (!ball.bowlingAnalysis?.pitchMap) returner.push('Pitch Map')
    if (!ball.battingAnalysis?.arrival) returner.push('Arrival')
    if (
      ball.battingAnalysis &&
      ball.battingAnalysis.footworkTypeId === null &&
      ball.battingAnalysis.shots.shotTypeId !== null &&
      Reference.ShotTypeOptions[ball.battingAnalysis.shots.shotTypeId] !== 'LEAVE'
    )
      returner.push('Footwork')
    if (isNil(ball.battingAnalysis?.shots.shotTypeId)) {
      returner.push('Shot Type')
    } else if (
      ball.battingAnalysis &&
      Reference.ShotTypeOptions[ball.battingAnalysis.shots.shotTypeId] !== 'LEAVE' &&
      isNil(ball.battingAnalysis?.shots.shotContactId)
    ) {
      returner.push('Shot Contact')
    }
    if (
      ball.runsBat ||
      (ball.runsExtra && ball.getExtraType !== 'WIDE' && (ball.getExtraType !== 'NO_BALL' || ball.runsBat))
    ) {
      // runs off the bat or byes/leg byes
      if (
        !ball.fieldingAnalysis?.fielded &&
        !ball.fieldingAnalysis?.misfielded &&
        ((ball.runsBat !== 4 && ball.runsBat !== 6 && ball.runsExtra !== 4 && ball.runsExtra !== 6) || ball.allRun)
      ) {
        // ball is in play (i.e. not a boundary of runs, byes or leg byes), and is not misfielded
        returner.push('Fielder')
      }
      if (!ball.battingAnalysis?.shots.wagonWheel) returner.push('Wagon Wheel')
    }
    if (
      ball.runsExtra &&
      (ball.getExtraType === 'BYE' || ball.getExtraType === 'NO_BALL_BYE') &&
      ball.byeBlameId === null
    ) {
      returner.push('Bye Blame')
    }
    returner = map(returner, area => {
      return area.replace(/ /g, '')
    })
    return returner.length > 0 ? returner : null
  }

  const runsAndExtrasTotal = (ball: IBallModel, negative?: boolean, allExtras?: boolean) => {
    const isByes: boolean = ball.getExtraType === 'BYE' || ball.getExtraType === 'NO_BALL_BYE'
    const isLegByes: boolean = ball.getExtraType === 'LEG_BYE' || ball.getExtraType === 'NO_BALL_LEG_BYE'
    const isNoBallWithExtras: boolean = ball.getExtraType === 'NO_BALL_BYE' || ball.getExtraType === 'NO_BALL_LEG_BYE'
    let total = ball.runsBat || 0
    if (ball.runsExtra && ball.runsExtra > 0 && (isByes || isLegByes || allExtras)) {
      total += ball.runsExtra
    }
    if (isNoBallWithExtras) total -= 1
    if (allExtras && ball.runsExtra) total -= 1
    return negative && total !== 0 ? total * -1 : total
  }

  const formatShotTypeOption = (value: string) => {
    let option: string = value.replace('_', ' ').toLowerCase()
    if (option === 'reverse sweep') option = 'rev. sweep'
    if (option === 'square cut') option = 'sq. cut'
    return capitalize(option)
  }

  const getNextBallInList = (ballList: IBallModel[], overNumber: number, ballNumber: number, endOfOver = false) => {
    const nextBallInSameOver = ballList.find(ballInList => {
      return ballInList.overNumber === overNumber && ballInList.ballNumber === ballNumber + 1
    })
    if (nextBallInSameOver) return nextBallInSameOver
    if (endOfOver) {
      const nextBallInNextOver = ballList.find(ballInList => {
        return ballInList.overNumber === overNumber + 1 && ballInList.ballNumber === 1
      })
      if (nextBallInNextOver) return nextBallInNextOver
      const nextBallInFollowingOver = ballList.find(ballInList => {
        return ballInList.overNumber === overNumber + 2 && ballInList.ballNumber === 1
      })
      if (nextBallInFollowingOver) return nextBallInFollowingOver
    }
    return
  }

  const getPreviousBallInList = (ballList: IBallModel[], overNumber: number, ballNumber: number) => {
    const previousBallInOver = ballList.find(ballInList => {
      return ballInList.overNumber === overNumber && ballInList.ballNumber === ballNumber - 1
    })
    if (previousBallInOver) return previousBallInOver
    const previousBallInPreviousOver = ballList.find(ballInList => {
      return (
        ballInList.overNumber === overNumber - 1 && STATIC_BALL_NUMBERS.find(number => number === ballInList.ballNumber)
      )
    })
    if (previousBallInPreviousOver) return previousBallInPreviousOver
    const previousBallInPrecedingOver = ballList.find(ballInList => {
      return (
        ballInList.overNumber === overNumber - 2 && STATIC_BALL_NUMBERS.find(number => number === ballInList.ballNumber)
      )
    })
    if (previousBallInPrecedingOver) return previousBallInPrecedingOver
    return
  }

  const deleteSpellOnUndo = (ball: IBallModel, innings?: IInningModel) => {
    const performance = innings?.bowlingPerformances.find(perf => perf.playerMp.id === ball.bowlerMp?.id)

    if (performance) {
      const spell = performance.getSpellFromBall(ball.overNumber, ball.ballNumber)

      if (spell?.startOver === ball.overNumber && spell?.startBall === ball.ballNumber) {
        // do the actual spell delete
        performance.removeSpell(spell.id)
      }
    }
  }

  const getBalls = (
    ballStore: IBallStoreModel,
    match: IClspMatchModel,
    mode: string,
    matchIsLocal: boolean,
    force?: boolean
  ) => {
    const inningsIds = flatten(match.matchTeams.map(team => team.innings.map(inn => inn.id)))
    if (inningsIds.length === 0 && !force) return
    if (force) {
      // set socket force back to false
      try {
        const root: IRootStore = getRoot(ballStore)
        root.socketStore.setDataForceReloadBalls(false)
      } catch {
        // eslint-disable-next-line
        console.error('unable to get parent of BallStore')
      }
    }
    inningsIds.forEach((id: string) => {
      const inning = ballStore.results.get(id)
      if (inning && !force) {
        return
      }
      if (matchIsLocal && !force) {
        fetchLocalBalls(ballStore, id, match.id)
        return
      }
      fetchBalls(ballStore, id, match.id, mode, force)
    })
    return
  }

  const fetchBalls = async (
    ballStore: IBallStoreModel,
    inningsId: string,
    matchId: string,
    mode: string,
    force = false
  ) => {
    if (includes(ballStore.activeRequests, matchId)) {
      return
    }
    ballStore.addActiveRequest(matchId)
    let dataAdded = false
    let inningDataFound = false
    const tokens = Auth.getTokens()
    const method = 'GET'
    const url = `${import.meta.env.VITE_API_URL}balls/${matchId}?mode=${mode}`
    // make request
    try {
      const response = await RequestHandler({
        method,
        url,
        headers: { Authorization: `Bearer ${tokens?.accessToken}` },
      })
      if (response instanceof Response && response.ok) {
        const data = await response.json()
        if (data && data.length > 0) {
          data.forEach((innings: DBInningsBalls) => {
            if (innings.inningsId === inningsId) inningDataFound = true
            if (ballStore.results.get(innings.inningsId) && !force) return
            innings = overIdPatcher(innings)
            const objToSave = {
              id: innings.inningsId,
              matchId,
              balls:
                innings.balls?.length && innings.balls.length > 0
                  ? // eslint-disable-next-line @typescript-eslint/no-explicit-any
                    innings.balls.map((b: any) => ballDataCleaner(b, matchId))
                  : [],
            }
            ballStore.insertInningsBalls(BallInningModel.create(objToSave))
            db.balls.put({ inningsId: objToSave.id, matchId: objToSave.matchId, balls: objToSave.balls })
          })
          if (!inningDataFound) {
            ballStore.insertInningsBalls(
              BallInningModel.create({
                id: inningsId,
                matchId,
                balls: [],
              })
            )
          }
          dataAdded = true
        }
      } else {
        ballStore.insertInningsBalls(
          BallInningModel.create({
            id: inningsId,
            matchId,
            balls: [],
          })
        )
      }
    } catch (error) {
      console.warn('Error getting Balls', error) // eslint-disable-line no-console
    }

    if (!dataAdded) {
      ballStore.insertInningsBalls(
        BallInningModel.create({
          id: inningsId,
          matchId,
          balls: [],
        })
      )
    }

    ballStore.removeActiveRequest(matchId)
  }

  const fetchLocalBalls = async (ballStore: IBallStoreModel, inningsId: string, matchId: string) => {
    if (includes(ballStore.activeRequests, inningsId)) {
      return
    }
    ballStore.addActiveRequest(inningsId)
    let innings = await db.balls.get(inningsId)

    if (innings) {
      innings = overIdPatcher(innings)
      ballStore.insertInningsBalls(
        BallInningModel.create({
          id: innings.inningsId,
          matchId,
          balls: innings.balls,
        })
      )
    } else {
      ballStore.insertInningsBalls(
        BallInningModel.create({
          id: inningsId,
          matchId,
          balls: [],
        })
      )
    }

    ballStore.removeActiveRequest(inningsId)
  }

  return {
    defaults: {
      bowlingDetails,
      battingDetails,
      predictiveDetails,
      fieldingDetails,
    },
    formatShotTypeOption,
    runsAndExtrasTotal,
    calculateBallNumberBowler,
    calculateBallNumberInnings,
    incompleteBallData,
    extrapolateReviewResult,
    getNextBallInList,
    getPreviousBallInList,
    deleteSpellOnUndo,
    getBalls,
  }
})()

export default BallHelpers
