import {
  Flex,
  Modal,
  ModalBody,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Spinner,
  Text,
} from '@chakra-ui/react'
import { observer } from 'mobx-react-lite'

import { useGameState } from '../../contexts/gameState.context'
import type { SocketModalProps } from '../../types/props'
import { Button } from '../Buttons/Button'

export const SocketModal = observer(({ socketStore, matchId, appMode }: SocketModalProps) => {
  const { matchStateReset } = useGameState()
  const modalClose = () => {
    socketStore.setPromptUser(false)
  }

  const establishConnection = () => {
    // by changing the socketStore matchId, we trigger the useEffect which will try to re-establish a connection
    socketStore.setMatchId(undefined)
    socketStore.setPromptUser(false)
  }

  const requestPrimaryAccess = () => {
    socketStore.setSocketRequestState('PRIMARY_REQUEST')
    socketStore.sendMessage(matchId, appMode, { socketRequestState: 'PRIMARY_REQUEST' })
  }

  const acceptPrimaryRequest = () => {
    socketStore.sendMessage(matchId, appMode, { socketRequestState: 'APPROVE_PRIMARY_REQUEST' })
    completeTransaction()
  }

  const denyPrimaryRequest = () => {
    socketStore.sendMessage(matchId, appMode, { socketRequestState: 'DENY_PRIMARY_REQUEST' })
    completeTransaction()
  }

  const completeTransaction = () => {
    socketStore.setSocketRequestState(undefined)
    socketStore.setPromptUser(false)
  }

  const loadPrimaryData = () => {
    socketStore.setSocketRequestState(undefined)
    matchStateReset()
    socketStore.setDataForceReloadMatch(true)
    socketStore.setDataForceReloadEvents(true)
    socketStore.setDataForceReloadBalls(true)
  }

  return (
    <Modal closeOnOverlayClick={false} isOpen={socketStore.promptUser} onClose={modalClose}>
      <ModalOverlay />
      <ModalContent>
        <ModalHeader>Scoring Connection Management</ModalHeader>
        {/* Not Connected */}
        {!socketStore.connected() && (
          <>
            <ModalBody>
              <Text>No Connection Detected</Text>
            </ModalBody>
            <ModalFooter>
              <Button
                colorScheme="green"
                onClick={establishConnection}
                mr="10px"
                data-testid="socketModalConnectButton"
              >
                Connect
              </Button>
              <Button onClick={modalClose} data-testid="socketModalCancelButton">
                Cancel
              </Button>
            </ModalFooter>
          </>
        )}
        {/* Not Primary Scorer */}
        {socketStore.connected() && !socketStore.primary && !socketStore.socketRequestState && (
          <>
            <ModalBody>
              <Text>Request primary scoring control?</Text>
            </ModalBody>
            <ModalFooter>
              <Button
                colorScheme="green"
                onClick={requestPrimaryAccess}
                mr="10px"
                data-testid="requestPrimaryAccessButton"
              >
                Request Primary Access
              </Button>
              <Button onClick={modalClose} data-testid="cancelRequestPrimaryModalButton">
                Cancel
              </Button>
            </ModalFooter>
          </>
        )}
        {socketStore.connected() && !socketStore.primary && socketStore.socketRequestState === 'PRIMARY_REQUEST' && (
          <>
            <ModalBody>
              <Text>Waiting for Primary Scorer response, if no response you will be promoted to Primary (~10s)</Text>
              <Flex justify="center" padding="7px">
                <Spinner thickness="4px" speed="0.75s" emptyColor="cls.gray.200" color="cls.blue.400" size="xl" />
              </Flex>
            </ModalBody>
          </>
        )}
        {/* eslint-disable-next-line max-len */}
        {socketStore.connected() &&
          !socketStore.primary &&
          socketStore.socketRequestState === 'DENY_PRIMARY_REQUEST' && (
            <>
              <ModalBody>
                <Text>Primary Scorer has denied your request for the Primary role</Text>
              </ModalBody>
              <ModalFooter>
                <Button colorScheme="gray" onClick={completeTransaction} data-testid="deniedModalOkButton">
                  Ok
                </Button>
              </ModalFooter>
            </>
          )}
        {socketStore.connected() &&
          !socketStore.primary &&
          socketStore.socketRequestState === 'APPROVE_PRIMARY_REQUEST' && (
            <>
              <ModalBody>
                <Text>
                  Handover complete, you are now a <strong>Secondary</strong> scorer
                </Text>
              </ModalBody>
              <ModalFooter>
                <Button colorScheme="gray" onClick={completeTransaction} data-testid="acceptSecondaryModalButton">
                  Ok, I am Secondary
                </Button>
              </ModalFooter>
            </>
          )}
        {/* Are Primary Scorer */}
        {socketStore.connected() && socketStore.primary && !socketStore.socketRequestState && (
          <>
            <ModalBody>
              <Text>You are primary scorer for this match</Text>
            </ModalBody>
            <ModalFooter>
              <Button onClick={modalClose} data-testid="acceptPrimaryModalButton">
                Close
              </Button>
            </ModalFooter>
          </>
        )}
        {socketStore.connected() && socketStore.primary && socketStore.socketRequestState === 'PRIMARY_REQUEST' && (
          <>
            <ModalBody>
              <Text>Another user is requesting Primary scorer role</Text>
            </ModalBody>
            <ModalFooter>
              <Button
                colorScheme="green"
                onClick={acceptPrimaryRequest}
                mr="10px"
                data-testid="acceptHandoverRequestButton"
              >
                Accept
              </Button>
              <Button colorScheme="red" onClick={denyPrimaryRequest} data-testid="rejectHandoverRequestButton">
                Reject
              </Button>
            </ModalFooter>
          </>
        )}
        {socketStore.connected() &&
          socketStore.primary &&
          socketStore.socketRequestState === 'APPROVE_PRIMARY_REQUEST' && (
            <>
              <ModalBody>
                <Text>
                  Request complete, you are becoming <strong>Primary</strong> scorer
                </Text>
              </ModalBody>
              <ModalFooter>
                <Button colorScheme="green" onClick={loadPrimaryData} data-testid="loadPrimaryScorerDataButton">
                  Load Primary Scorer Data
                </Button>
              </ModalFooter>
            </>
          )}
        {socketStore.connected() && socketStore.primary && socketStore.forceDataReloadMatch && (
          <>
            <ModalBody>
              <Text>Loading Primary Scorer Data</Text>
              <Flex justify="center" padding="7px">
                <Spinner thickness="4px" speed="0.75s" emptyColor="cls.gray.200" color="cls.blue.400" size="xl" />
              </Flex>
            </ModalBody>
          </>
        )}
      </ModalContent>
    </Modal>
  )
})
