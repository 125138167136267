import type { IBallModel } from '@clsplus/cls-plus-data-models'

import type { EnvironmentType } from './generalHelpers'

export const editPayload = ({
  env,
  mode,
  matchId,
  user,
  ballsAffected,
  editBall,
  currentBall,
}: {
  env: EnvironmentType
  mode: string | undefined
  matchId?: string
  user: { 'cognito:username'?: string; email?: string }
  ballsAffected: number
  editBall?: IBallModel
  currentBall?: IBallModel
}) => {
  return {
    env,
    mode,
    matchId,
    user,
    ballsAffected,
    editBall: editBall,
    currentBall: currentBall,
  }
}
