import { Box, Flex } from '@chakra-ui/react'
import { indexOf } from 'lodash'
import { observer } from 'mobx-react-lite'

import { db } from '../../data/dexie/Database'
import {
  WeatherAtmosOptions,
  WeatherRainOptions,
  WeatherTemperatureOptions,
  WeatherWindOptions,
} from '../../data/reference'
import S3PHelpers from '../../helpers/s3pHelpers'
import type { WeatherProps } from '../../types/props'
import { SimpleStateButton } from '../Buttons/SimpleStateButton'
import SubHeading from '../Headings/SubHeading'

export const Weather = observer(({ game, mode, conditions }: WeatherProps) => {
  const changeConditionsWeather = ({ value, target }: { value: string; target?: string | null }) => {
    if (!value || !target) return
    if (target === 'rain') {
      game?.setRain(value)
    }
    if (target === 'atmos') {
      game?.setAtmos(value)
    }
    if (target === 'temperature') {
      game?.setTemperature(value)
    }
    if (target === 'wind') {
      game?.setWind(value)
    }
    if (mode !== 'fielding') {
      db.createS3PMessage(S3PHelpers.metadata(mode, game), S3PHelpers.weatherConditions(conditions, game), true)
    }
  }

  return (
    <Box paddingTop="14px">
      <SubHeading text="Weather Conditions" secondary />
      <SubHeading text="Temperature" icon={['far', 'temperature-hot']} tertiary />
      <Flex paddingBottom="14px" flexDirection="column">
        <Flex direction="row" padding="4px 0">
          <SimpleStateButton
            onClick={changeConditionsWeather}
            value="VERY_HOT"
            target="temperature"
            isActive={conditions?.temperatureTypeId === indexOf(WeatherTemperatureOptions, 'VERY_HOT')}
            isFlex
            isFirst
            data-testid="tempVeryHot"
          >
            Very Hot (&gt; 35C)
          </SimpleStateButton>
          <SimpleStateButton
            onClick={changeConditionsWeather}
            value="HOT"
            target="temperature"
            isActive={conditions?.temperatureTypeId === indexOf(WeatherTemperatureOptions, 'HOT')}
            isFlex
            isMiddle={mode === 'advanced'}
            isLast={mode !== 'advanced'}
            data-testid="tempHot"
          >
            Hot (30-35)
          </SimpleStateButton>
        </Flex>
        <Flex direction="row" padding="4px 0">
          <SimpleStateButton
            onClick={changeConditionsWeather}
            value="WARM"
            target="temperature"
            isActive={conditions?.temperatureTypeId === indexOf(WeatherTemperatureOptions, 'WARM')}
            isFlex
            isFirst
            data-testid="tempWarm"
          >
            Warm (25-30)
          </SimpleStateButton>
          <SimpleStateButton
            onClick={changeConditionsWeather}
            value="MILD"
            target="temperature"
            isActive={conditions?.temperatureTypeId === indexOf(WeatherTemperatureOptions, 'MILD')}
            isFlex
            isMiddle={mode === 'advanced'}
            isLast={mode !== 'advanced'}
            data-testid="tempMild"
          >
            Mild (15-25)
          </SimpleStateButton>
        </Flex>
        <Flex direction="row" padding="4px 0">
          <SimpleStateButton
            onClick={changeConditionsWeather}
            value="COLD"
            target="temperature"
            isActive={conditions?.temperatureTypeId === indexOf(WeatherTemperatureOptions, 'COLD')}
            isFlex
            isFirst
            data-testid="tempCold"
          >
            Cold (10-15)
          </SimpleStateButton>
          <SimpleStateButton
            onClick={changeConditionsWeather}
            value="VERY_COLD"
            target="temperature"
            isActive={conditions?.temperatureTypeId === indexOf(WeatherTemperatureOptions, 'VERY_COLD')}
            isFlex
            isLast
            data-testid="tempVeryCold"
          >
            Very cold (&lt; 10)
          </SimpleStateButton>
        </Flex>
      </Flex>
      <SubHeading text="Sky" icon={['far', 'sun-cloud']} tertiary />
      <Flex paddingBottom="14px" flexDirection={mode === 'advanced' ? 'row' : 'column'}>
        <Flex flex={1} direction="row" padding="4px 0">
          <SimpleStateButton
            onClick={changeConditionsWeather}
            value="CLEAR"
            target="atmos"
            isActive={conditions?.atmosTypeId === indexOf(WeatherAtmosOptions, 'CLEAR')}
            isFlex
            isFirst
            data-testid="skyClear"
          >
            Clear
          </SimpleStateButton>
          <SimpleStateButton
            onClick={changeConditionsWeather}
            value="PARTLY_CLOUDY"
            target="atmos"
            isActive={conditions?.atmosTypeId === indexOf(WeatherAtmosOptions, 'PARTLY_CLOUDY')}
            isFlex
            isMiddle
            data-testid="skyPartlyCloudy"
          >
            Partly Cloudy
          </SimpleStateButton>
        </Flex>
        <Flex flex={mode === 'advanced' ? 0.5 : 1} direction="row" padding="4px 0">
          <SimpleStateButton
            onClick={changeConditionsWeather}
            value="CLOUDY"
            target="atmos"
            isActive={conditions?.atmosTypeId === indexOf(WeatherAtmosOptions, 'CLOUDY')}
            isFlex
            isLast={mode === 'advanced'}
            isFirst={mode !== 'advanced'}
            data-testid="skyCloudy"
          >
            Cloudy
          </SimpleStateButton>
        </Flex>
      </Flex>
      <SubHeading text="Rain" icon={['far', 'cloud-showers']} tertiary />
      <Flex paddingBottom="14px" flexDirection={mode === 'advanced' ? 'row' : 'column'}>
        <Flex flex={1} direction="row" padding="4px 0">
          <SimpleStateButton
            onClick={changeConditionsWeather}
            value="NO_RAIN"
            target="rain"
            isActive={conditions?.rainTypeId === indexOf(WeatherRainOptions, 'NO_RAIN')}
            isFlex
            isFirst
            data-testid="rainNone"
          >
            No Rain
          </SimpleStateButton>
          <SimpleStateButton
            onClick={changeConditionsWeather}
            value="SHOWERS"
            target="rain"
            isActive={conditions?.rainTypeId === indexOf(WeatherRainOptions, 'SHOWERS')}
            isFlex
            isMiddle={mode === 'advanced'}
            isLast={mode !== 'advanced'}
            data-testid="rainShowers"
          >
            Showers
          </SimpleStateButton>
        </Flex>
        <Flex flex={1} direction="row" padding="4px 0">
          <SimpleStateButton
            onClick={changeConditionsWeather}
            value="RAIN"
            target="rain"
            isActive={conditions?.rainTypeId === indexOf(WeatherRainOptions, 'RAIN')}
            isFlex
            isMiddle={mode === 'advanced'}
            isFirst={mode !== 'advanced'}
            data-testid="rainRain"
          >
            Rain
          </SimpleStateButton>
          <SimpleStateButton
            onClick={changeConditionsWeather}
            value="HEAVY_RAIN"
            target="rain"
            isActive={conditions?.rainTypeId === indexOf(WeatherRainOptions, 'HEAVY_RAIN')}
            isFlex
            isLast
            data-testid="rainHeavyRain"
          >
            Heavy Rain
          </SimpleStateButton>
        </Flex>
      </Flex>
      <SubHeading text="Wind" icon={['far', 'wind']} tertiary />
      <Flex paddingBottom="14px" flexDirection={mode === 'advanced' ? 'row' : 'column'}>
        <Flex flex={1} direction="row" padding="4px 0">
          <SimpleStateButton
            onClick={changeConditionsWeather}
            value="NO_WIND"
            target="wind"
            isActive={conditions?.windTypeId === indexOf(WeatherWindOptions, 'NO_WIND')}
            isFlex
            isFirst
            data-testid="windNone"
          >
            No Wind
          </SimpleStateButton>
          <SimpleStateButton
            onClick={changeConditionsWeather}
            value="BREEZE"
            target="wind"
            isActive={conditions?.windTypeId === indexOf(WeatherWindOptions, 'BREEZE')}
            isFlex
            isMiddle={mode === 'advanced'}
            isLast={mode !== 'advanced'}
            data-testid="windBreeze"
          >
            Breeze
          </SimpleStateButton>
        </Flex>
        <Flex flex={1} direction="row" padding="4px 0">
          <SimpleStateButton
            onClick={changeConditionsWeather}
            value="WINDY"
            target="wind"
            isActive={conditions?.windTypeId === indexOf(WeatherWindOptions, 'WINDY')}
            isFlex
            isMiddle={mode === 'advanced'}
            isFirst={mode !== 'advanced'}
            data-testid="windWindy"
          >
            Windy
          </SimpleStateButton>
          <SimpleStateButton
            onClick={changeConditionsWeather}
            value="GALE"
            target="wind"
            isActive={conditions?.windTypeId === indexOf(WeatherWindOptions, 'GALE')}
            isFlex
            isLast
            data-testid="windGale"
          >
            Gale
          </SimpleStateButton>
        </Flex>
      </Flex>
    </Box>
  )
})
