import { Box, Flex } from '@chakra-ui/react'
import type { IBallStoreModel } from '@clsplus/cls-plus-data-models'
import { useParams } from 'react-router-dom'

import SubHeading from '../components/Headings/SubHeading'
import { SettingsContainer } from '../components/Settings/SettingsContainer'
import { TeamNames } from '../components/TeamNames/TeamNames'
import { useMst } from '../data/stores/rootStore'
import type { IDetailedMatchStore, ISettingsModel, ITimelineStore } from '../types/models'
import type { SettingsProps } from '../types/props'

const Settings = ({ mode }: SettingsProps) => {
  const {
    detailedMatches,
    balls,
    appSettings,
  }: {
    detailedMatches: IDetailedMatchStore
    balls: IBallStoreModel
    timelineEvents: ITimelineStore
    appSettings: ISettingsModel
  } = useMst()
  const { id } = useParams()
  if (!id) return null
  detailedMatches.getMatch(id, appSettings.appMode)
  const game = detailedMatches.results.get(id)?.match
  const activeInning = game?.getActiveInning()
  const currentBall = activeInning && balls?.getNewestBall(activeInning.id, true)

  if (!game) return null

  return (
    <>
      <Flex
        marginX={mode !== 'advanced' ? '0px' : ['0', '0', '14px', '14px']}
        marginBottom="20px"
        h={mode === 'fielding' ? 'auto' : '100%'}
        w={mode !== 'advanced' ? ['100%', '100%', 'auto', 'auto'] : undefined}
        justifyContent={mode !== 'advanced' ? 'center' : 'flex-start'}
      >
        <Flex
          flex="10"
          direction="column"
          w={mode !== 'advanced' ? ['100%', '100%', 'auto', 'auto'] : undefined}
          maxW={mode !== 'advanced' ? ['100%', '100%', '1280px', '1280px'] : undefined}
        >
          <Box paddingY="14px">
            <TeamNames game={game} mode={mode} />
          </Box>
          <Flex
            flex={1}
            direction="column"
            bg="white"
            borderTopRightRadius={mode === 'advanced' ? '7px' : ['0px', '0px', '7px', '7px']}
            borderTopLeftRadius={mode === 'advanced' ? '7px' : ['0px', '0px', '7px', '7px']}
            padding={mode === 'advanced' ? '14px 21px 0' : '14px 14px 0'}
          >
            <Flex direction="row" justifyContent="space-between" alignItems="center" marginBottom="7px">
              <Box>
                <SubHeading text="Settings" />
              </Box>
            </Flex>
            <SettingsContainer
              game={game}
              currentBall={currentBall}
              mode={mode}
              isScoringManually={mode === 'advanced' && appSettings.manualScoring.active}
              settingsConfirmed
              teamsConfirmed
            />
          </Flex>
        </Flex>
        <Flex flex="2" direction="column" display={mode !== 'advanced' ? 'none' : undefined} />
      </Flex>
    </>
  )
}

export default Settings
