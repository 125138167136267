import { zonedTimeToUtc } from 'date-fns-tz'

export const toCamelCase = (title: string): string => {
  const text = title.replace(' ', '')
  return text[0].toLocaleLowerCase() + text.slice(1, -1)
}

export const posNegNumber = (num: number | string): string => {
  if (Number(num) > 0) return `+${num}`
  return num.toString()
}

export const suffixNumber = (num: number): string => {
  const rem = num % 10
  const output = (num % 100) / 10 === 1 ? 'th' : rem === 1 ? 'st' : rem === 2 ? 'nd' : rem === 3 ? 'rd' : 'th'
  return `${num}${output}`
}

export const swapObjectValues = <TObject>(obj: TObject, key1: keyof TObject, key2: keyof TObject) => {
  const tempObject = JSON.parse(JSON.stringify(obj))
  obj[key1] = tempObject[key2]
  obj[key2] = tempObject[key1]
  return obj
}

export const timeMachineDate = (baseline: string, activated: string) => {
  // baseline (in milliseconds) + diff b/w current time and time machine activation time (in milliesconds)
  // ... then converted back to a Date string
  return new Date(
    new Date(baseline).getTime() +
      Math.abs(
        zonedTimeToUtc(new Date(), Intl.DateTimeFormat().resolvedOptions().timeZone).getTime() -
          new Date(activated).getTime()
      )
  ).toISOString()
}

export function getFormattedDate(date: Date): string {
  const dateFormatter = new Intl.DateTimeFormat('en-US', {
    year: 'numeric',
    month: '2-digit',
    day: '2-digit',
  })

  const { day, month, year } = dateFormatter.formatToParts(date).reduce<{ [key: string]: string }>((acc, cur) => {
    acc[cur.type] = cur.value
    return acc
  }, {})

  return `${year}-${month}-${day}`
}

export type EnvironmentType = 'sandbox' | 'production' | 'uat' | 'test' | undefined

export function currentEnvironment(): EnvironmentType {
  const apiUrl = import.meta.env.VITE_API_URL || ''

  if (apiUrl.includes('api.sandbox.clsplus')) {
    return 'sandbox'
  }
  if (apiUrl.includes('api.uat.clsplus')) {
    return 'uat'
  }
  if (apiUrl.includes('api.test.clsplus')) {
    return 'test'
  }
  if (apiUrl.includes('api.clsplus')) {
    return 'production'
  }
  return undefined
}
