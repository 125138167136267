import { Alert, AlertIcon, Box, Flex } from '@chakra-ui/react'
import type { IBallModel, IMatchPlayerModel, ITimelineEventModel } from '@clsplus/cls-plus-data-models'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { find } from 'lodash'
import { observer } from 'mobx-react-lite'
import { useEffect, useRef, useState } from 'react'
import { VariableSizeList as List } from 'react-window'
import ResizeObserver from 'resize-observer-polyfill'

import { TimelineTypes } from '../../data/reference'
import MatchHelpers from '../../helpers/matchHelpers'
import type { DropdownCallbackArgs, DropdownOption } from '../../types'
import type { ActivityLogProps } from '../../types/props'
import { CustomButton } from '../Buttons/CustomButton'
import Dropdown from '../Dropdown/Dropdown'
import SubHeading from '../Headings/SubHeading'
import BallItem from './BallItem'
import EventItem from './EventItem'

const getOverEditOptions = (
  ballNumber: number,
  currentOver: boolean,
  bowlers: IMatchPlayerModel[],
  allowInsertingBalls: boolean
) => {
  const opts: DropdownOption[][] = []
  if (bowlers.length === 1) {
    opts.push([{ value: 'changeBowler', label: 'Change bowler' }])
  }
  if (allowInsertingBalls) {
    opts.push([])
    for (let o = currentOver ? ballNumber : ballNumber + 1; o >= 1; o--) {
      opts[opts.length - 1].push({ value: `${o}`, label: `Insert ball as ball #${o}` })
    }
  }
  return opts
}

const getRowHeight = (
  index: number,
  item: IBallModel | ITimelineEventModel,
  latestBall?: IBallModel,
  hasBalls?: boolean
) => {
  if (
    hasBalls &&
    (('ballNumber' in item && (item.endOfOver || (item.isNewestBallInOver && index === 0))) ||
      (!('ballNumber' in item) && !latestBall?.endOfOver && index === 0))
  ) {
    // first item of over - include over heading
    return 109
  }
  // all other items
  return 65
}

const ActivityLog = observer(
  ({
    game,
    balls,
    relevantTimelineEvents,
    mode,
    manualEndOver,
    insertBall,
    editBall,
    triggerEditBall,
    cancelEditBall,
    triggerUndo,
    triggerUndoEvent,
    triggerMissedBall,
    inningsInOrder,
    currentInning,
    setCurrentInning,
    closedInning,
    editingDisabled,
    actionsDisabled,
    triggerNewBowler,
    setBowlerPerformance,
  }: ActivityLogProps) => {
    const ref = useRef<HTMLDivElement>(null)
    const listRef = useRef<List>(null)
    const [height, setHeight] = useState(0)
    const [width, setWidth] = useState(0)
    const ballsAndEvents = MatchHelpers.sortBallsAndEvents(balls, relevantTimelineEvents)
    const latestBall = MatchHelpers.getLatestBall(ballsAndEvents[0], 0, ballsAndEvents)
    const coreModeEditingDisabled = mode === 'core' && import.meta.env.VITE_ENV_BETTING_EDITING_DISABLED === 'true'

    useEffect(() => {
      // add an effect on mount that will watch for size changes in the
      // element inside the ref
      const el = ref.current
      if (!el) return

      function handleResize() {
        // @ts-ignore
        const { height, width } = el.getBoundingClientRect()
        setHeight(height)
        setWidth(width)
      }

      // resize observer is a tool you can use to watch for size changes efficiently
      const resizeObserver = new ResizeObserver(handleResize)
      resizeObserver.observe(el)

      return () => resizeObserver.disconnect()
    }, [])

    if (!ballsAndEvents) return null

    const itemUndoIndex = ballsAndEvents.findIndex(item => {
      return (
        ('timestamps' in item && item.isCurrentOver() && item.isNewestBallInOver) ||
        (!('timestamps' in item) && item.isDeletable)
      )
    })

    const handleBallClick = (ball: IBallModel, isNewestOver: boolean, isNewestBallInOver: boolean) => {
      if (
        coreModeEditingDisabled ||
        !triggerEditBall ||
        editingDisabled ||
        (mode === 'fielding' &&
          (!ball.fieldingAnalysis?.fieldingPositions || ball.fieldingAnalysis?.fieldingPositions.length === 0))
      )
        return
      triggerEditBall({ ball, isNewestOver, isNewestBallInOver })
    }

    const changeInnings = ({ value }: DropdownCallbackArgs) => {
      if (!setCurrentInning || !value) return
      const selectedInning = game.getInningById(value)
      if (selectedInning) {
        if (editBall && cancelEditBall) cancelEditBall()
        setCurrentInning({
          id: value,
          value: MatchHelpers.getInningsLabel(selectedInning, selectedInning.getBattingTeam.shortName),
          isActiveInning: find(inningsInOrder, { isActiveInning: true })?.value === value,
          inning: selectedInning,
        })
      }
    }

    const handleOverEditOptions = (lastBall: IBallModel, value?: string) => {
      if (value === 'changeBowler' && currentInning && lastBall.bowlerMp && triggerNewBowler && setBowlerPerformance) {
        // changing bowler for the entire over
        setBowlerPerformance(currentInning.inning.getBowlingPerformance(lastBall.bowlerMp.id))
        triggerNewBowler('CHANGE_OVER', lastBall)
      } else if (value !== 'changeBowler' && insertBall) {
        // inserting a ball
        insertBall(lastBall.overNumber, Number(value))
      }
    }

    const RowRenderer = ({ index, style }: { index: number; style: React.CSSProperties }) => {
      const item = ballsAndEvents[index]
      const lastBall: IBallModel | undefined = MatchHelpers.getLatestBall(item, index, ballsAndEvents)
      const isBall = 'ballNumber' in item
      const isEvent = !('ballNumber' in item)
      const rowRef = useRef<HTMLDivElement>(null)
      const undoDisabled =
        (actionsDisabled && ((isBall && mode === 'fielding') || !closedInning)) ||
        (isBall &&
          mode === 'fielding' &&
          (!item.fieldingAnalysis?.fieldingPositions || item.fieldingAnalysis?.fieldingPositions.length === 0))

      // @ts-ignore
      const relatedEvent: ITimelineEventModel | undefined =
        isEvent &&
        TimelineTypes[item.matchEventTypeId] === 'NON_BALL_DISMISSAL' &&
        index > 0 &&
        !('ballNumber' in ballsAndEvents[index - 1])
          ? ballsAndEvents[index - 1]
          : undefined

      useEffect(() => {
        if (rowRef.current && listRef.current) {
          listRef.current.resetAfterIndex(0)
        }
      }, [rowRef])

      return (
        <Box style={style} ref={rowRef}>
          {lastBall &&
            ((isBall && (lastBall.endOfOver || (lastBall.isNewestBallInOver && index === 0))) ||
              (isEvent && !lastBall.endOfOver && index === 0)) && (
              <Box
                key={`heading-${lastBall.overNumber}_${lastBall.ballNumber}`}
                data-testid={`activityLogOver${lastBall.overNumber}`}
              >
                <Flex flexDirection="row" paddingTop={!lastBall.isCurrentOver() ? '7px' : 0} height="44px">
                  <Flex flex={5}>
                    <SubHeading text={`OVER ${lastBall.overNumber + 1}`} secondary />
                  </Flex>
                  {mode !== 'fielding' && !editBall && !editingDisabled && !closedInning && currentInning && (
                    <Flex flex={1}>
                      {insertBall && !coreModeEditingDisabled && (
                        <Dropdown
                          id={`insertBallOver${lastBall.overNumber}`}
                          data-testid={`insertBallOver${lastBall.overNumber}`}
                          value={<FontAwesomeIcon icon={['fas', 'ellipsis-v']} size="sm" />}
                          options={getOverEditOptions(
                            lastBall.ballNumber,
                            lastBall.isCurrentOver(true),
                            lastBall.getOverBowlers(),
                            currentInning.isActiveInning && !actionsDisabled
                          )}
                          onChange={({ value }) => handleOverEditOptions(lastBall, value)}
                          listWidth="180px"
                          isWhite={mode === 'advanced'}
                          ignoreState
                        />
                      )}
                      {lastBall.isCurrentOver(true) && !actionsDisabled && currentInning.isActiveInning && (
                        <Box marginLeft={insertBall ? '4px' : 'unset'}>
                          <CustomButton data-testid="endOver" onClick={manualEndOver} isWhite={mode === 'advanced'}>
                            End Over
                          </CustomButton>
                        </Box>
                      )}
                    </Flex>
                  )}
                </Flex>
              </Box>
            )}
          {item && isBall && (
            <BallItem
              ball={item}
              editBall={editBall}
              triggerEditBall={triggerEditBall}
              mode={mode}
              triggerUndo={triggerUndo}
              handleBallClick={handleBallClick}
              hasUndo={itemUndoIndex === index}
              undoDisabled={undoDisabled || editingDisabled}
              noBallValue={game.matchConfigs.noBallRuns || 1}
            />
          )}
          {item && isEvent && (
            <EventItem
              event={item}
              mode={mode}
              editBall={editBall}
              hasUndo={itemUndoIndex === index}
              undoDisabled={undoDisabled || editingDisabled}
              triggerUndo={() => {
                if (triggerUndoEvent) triggerUndoEvent(item, relatedEvent)
              }}
            />
          )}
        </Box>
      )
    }

    return (
      <Flex flex={1} direction="column" w="100%" h="100%">
        <Flex direction="row" marginBottom={mode === 'advanced' ? '14px' : '7px'}>
          <Flex flex={1} direction="row" paddingTop="4px">
            <Flex flex={1}>
              <SubHeading text="Activity Log" secondary bold />
            </Flex>
            {mode === 'fielding' && currentInning?.isActiveInning && !actionsDisabled && (
              <Flex flex={1} justifyContent="flex-end">
                <CustomButton
                  data-testid="triggerMissingBall"
                  onClick={() => triggerMissedBall && triggerMissedBall()}
                  paddingX="4px"
                  paddingXOuter="4px"
                  isWhite
                >
                  + Missed ball
                </CustomButton>
              </Flex>
            )}
          </Flex>
          {(!actionsDisabled || editBall || closedInning) &&
            mode !== 'fielding' &&
            currentInning &&
            inningsInOrder &&
            inningsInOrder.length > 1 && (
              <Flex flex={mode === 'advanced' ? 0.55 : 1}>
                <Dropdown
                  target="current_innings"
                  options={inningsInOrder}
                  value={currentInning.value}
                  onChange={changeInnings}
                  preserveCase
                  ignoreState
                  isWhite={mode === 'advanced'}
                  data-testid="currentInningsDropdown"
                />
              </Flex>
            )}
        </Flex>
        {coreModeEditingDisabled && (
          <Box marginBottom="4px">
            <Alert status="warning">
              <AlertIcon />
              Ball editing is temporarily disabled for this mode
            </Alert>
          </Box>
        )}
        <Box ref={ref} flex="1 1 auto" overflowY="hidden" data-testid="activityLog">
          <List
            height={height}
            itemCount={ballsAndEvents.length}
            itemSize={(index: number) => getRowHeight(index, ballsAndEvents[index], latestBall, itemUndoIndex > -1)}
            width={width}
            ref={listRef}
          >
            {RowRenderer}
          </List>
        </Box>
      </Flex>
    )
  }
)

export default ActivityLog
