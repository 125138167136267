import { ChakraProvider } from '@chakra-ui/react'
import { datadogRum } from '@datadog/browser-rum'
import { Global } from '@emotion/react'
import { createRoot } from 'react-dom/client'

import { App } from './App'
import { AdExtensionSniffer } from './components/AdExtensionSniffer/AdExtensionSniffer'
import { ServiceWorker } from './components/ServiceWorker/ServiceWorker'
import { GlobalProvider } from './data/stores/rootStore'
import Auth from './helpers/auth'
import Theme from './theme/theme'

if (import.meta.env.VITE_ENV !== 'DEV') {
  datadogRum.init({
    applicationId: '6e0df1ba-f240-45ca-9203-c922be44bee0',
    clientToken: 'pub551178126fd430cb922563ae9b0b8ae4',
    site: 'datadoghq.com',
    service: 'clsp-score',
    env: import.meta.env.VITE_DATADOG_ENV,
    version: __APP_VERSION__,
    sessionSampleRate: 100,
    sessionReplaySampleRate: 100,
    trackUserInteractions: true,
    enableExperimentalFeatures: ['clickmap'],
    allowedTracingUrls: [new RegExp(`${import.meta.env.VITE_API_URL}*`)],
    defaultPrivacyLevel: 'allow',
  })

  datadogRum.setUser({
    id: Auth.getUserProfile()?.['cognito:username'],
  })
  datadogRum.startSessionReplayRecording()
}

const AppWithServiceWorker = () => {
  return (
    <>
      <App />
      <ServiceWorker />
      <AdExtensionSniffer />
    </>
  )
}

// eslint-disable-next-line @typescript-eslint/no-non-null-assertion
const root = createRoot(document.getElementById('root')!)

root.render(
  <ChakraProvider theme={Theme}>
    <Global
      styles={{
        html: {
          fontFamily: 'Source Sans Pro, sans-serif',
        },
        body: {
          backgroundColor: Theme.colors.cls.backgroundGray,
        },
      }}
    />
    <GlobalProvider>
      <AppWithServiceWorker />
    </GlobalProvider>
  </ChakraProvider>
)
